const state = () => ({
  unsigned: [],
  certifiedEmail: null,
  showInstructions: false,
  contract: null
})

const getters = {
  showInstructions: state => state.showInstructions,
  unsigned: state => state.unsigned,
  contract: state => state.contract,
  certifiedEmail: state => state.certifiedEmail,
  currentDoc: state => (state.unsigned.length ? state.unsigned[0] : null)
}

const mutations = {
  receiveUnsigned(state, docs) {
    state.unsigned = docs
  },
  receiveContract(state, contract) {
    state.contract = contract
  },
  receiveCertifiedEmail(state, email) {
    state.certifiedEmail = email
  },
  receiveShowInstructions(state, showInstructions) {
    state.showInstructions = showInstructions
  },
  removeCurrentDoc(state) {
    state.unsigned.shift()
  }
}

const actions = {
  getUnsigned({ commit, rootState }, app) {
    let backendApp = 'bodygram-backend'

    if (app === 'FIT') {
      backendApp = 'fitness-app-backend'
    } else if (app === 'REG') {
      backendApp = 'regional-app-backend'
    }

    return this.$axios
      .post(`/${backendApp}/v1/core/contract/unsigned`, {
        accessToken: rootState.user.token
        // locale: this.$i18n.locale === 'it' ? 'it_IT' : 'en_US'
      })
      .then(response => {
        if (response.data.contract) {
          commit('receiveContract', response.data.contract)
          commit('receiveUnsigned', response.data.contract.docs)
        }
        commit('receiveShowInstructions', response.data.showInstructions)
        commit('receiveCertifiedEmail', response.data.certifiedEmail)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
