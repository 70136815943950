const state = () => ({
  list: null,
  exam: null,
  loadReport: null
})

const getters = {
  list: state => state.list,
  exam: state => state.exam,
  loadReport: state => state.loadReport
}

const mutations = {
  receiveList(state, list) {
    state.list = list
  },
  receiveExam(state, exam) {
    state.exam = exam
  },
  receiveLoadReport(state, loadReport) {
    state.loadReport = loadReport
  }
}

const actions = {
  getList({ commit, rootState }, patientId) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/trainings/list', {
        accessToken: rootState.user.token,
        patientId: patientId,
        filters: {
          singleOnly: true
        }
      })
      .then(response => {
        commit('receiveList', response.data.trainings)
      })
  },
  getExam({ commit, rootState }, examId) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/trainings/get', {
        accessToken: rootState.user.token,
        id: examId
      })
      .then(response => {
        commit('receiveExam', response.data.training)
      })
  },
  createExam({ dispatch, rootState, rootGetters }, exam) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/trainings/create', {
        accessToken: rootState.user.token,
        training: exam
      })
      .then(response => {
        if (!rootGetters['profile/wizard']) {
          dispatch('getList', exam.patientId)
        }
        if (rootState.profile.isBaseline) {
          dispatch('profile/getProfile', exam.patientId, { root: true })
        }
        return response
      })
  },
  updateExam({ dispatch, rootState }, exam) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/trainings/update', {
        accessToken: rootState.user.token,
        training: exam
      })
      .then(() => {
        dispatch('getExam', exam.id)
        if (rootState.profile.isBaseline) {
          dispatch('profile/getProfile', exam.patientId, { root: true })
        }
      })
  },
  deleteExam({ rootState }, examId) {
    return this.$axios.post('/fitness-app-backend/v1/app/trainings/delete', {
      accessToken: rootState.user.token,
      id: examId
    })
  },
  getLoadReport({ commit, rootState }, examId) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/reports/training-load', {
        accessToken: rootState.user.token,
        trainingId: examId
      })
      .then(response => {
        const report = response.data.training
        report.loads.push({
          ...response.data.training.totals,
          title: 'weeklyTotals'
        })
        report.loads.push({
          ...response.data.training.averages,
          title: 'dailyAvg'
        })
        commit('receiveLoadReport', report)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
