export default function({ app, store, redirect }) {
  const ketoApplication = store.getters['applications/keto']

  if (
    ketoApplication &&
    ketoApplication.signature &&
    ((typeof ketoApplication.signature.daysToLock === 'number' &&
      ketoApplication.signature.daysToLock <= 0) ||
      ketoApplication.signature.forceSigningContract)
  ) {
    redirect(app.localePath('/accept?app=FIT'))
  }
}
