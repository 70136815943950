import Vue from 'vue'
import VeeValidate from 'vee-validate'

Vue.use(VeeValidate, {
  dictionary: {
    en: {
      messages: {
        excluded: 'Name is already present'
      }
    }
  },
  fieldsBagName: 'veeFields'
})
