export default function({ app, store, redirect }) {
  const bodygramApplication = store.getters['applications/bodygram']

  if (
    bodygramApplication &&
    bodygramApplication.signature &&
    ((typeof bodygramApplication.signature.daysToLock === 'number' &&
      bodygramApplication.signature.daysToLock <= 0) ||
      bodygramApplication.signature.forceSigningContract)
  ) {
    redirect(app.localePath('/accept?app=BOD'))
  }
}
