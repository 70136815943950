export const state = () => ({
  type: null,
  getDataFromNutrilabMilliseconds: null,
  updateAvailable: false
})

export const getters = {
  type: state => state.type,
  getDataFromNutrilabMilliseconds: state =>
    state.getDataFromNutrilabMilliseconds,
  updateAvailable: state => state.updateAvailable
}

export const mutations = {
  setType(state, type) {
    state.type = type
  },
  setUpdateAvailable(state, value) {
    state.updateAvailable = value
  },
  setGetDataFromNutrilabMilliseconds(state, value) {
    state.getDataFromNutrilabMilliseconds = value
  }
}

export const actions = {
  async getConfig({ commit, rootGetters }) {
    try {
      if (process.env.platform === 'electron') {
        const { data } = await this.$axios.post(
          `${rootGetters['network/localAPI']}/config/get`,
          {}
        )

        commit('setType', data.type)
        commit(
          'setGetDataFromNutrilabMilliseconds',
          data.getDataFromNutrilabMilliseconds
        )
      }

      if (rootGetters['config/type'] === 'shared') {
        this.$axios.defaults.baseURL = rootGetters['network/localAPI']
      } else {
        this.$axios.defaults.baseURL = rootGetters['network/onLine']
          ? process.env.baseUrl
          : rootGetters['network/localAPI']
      }
    } catch (error) {
      console.error(error)
    }
  }
}
