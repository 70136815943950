const state = () => ({
  deleted: null,
  exam: null,
  list: null,
  chartLoaded: false
})

const getters = {
  deleted: state => state.deleted,
  exam: state => state.exam,
  list: state => state.list,
  chartLoaded: state => state.chartLoaded
}

const mutations = {
  receiveDeleted(state, exams) {
    state.deleted = exams
  },
  receiveExam(state, exam) {
    state.exam = exam
  },
  receiveList(state, list) {
    state.list = list
  },
  receiveChartLoad(state) {
    state.chartLoaded = true
  }
}

const actions = {
  setChartLoaded({ commit }) {
    commit('receiveChartLoad')
  },
  getList({ commit, rootState }, patientId) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/bia/list', {
        accessToken: rootState.user.token,
        patientId: patientId,
        filters: {
          singleOnly: true
        }
      })
      .then(response => {
        commit('receiveList', response.data.bia)
      })
  },
  getDeleted({ commit, rootState }, patientId) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/bia/list', {
        accessToken: rootState.user.token,
        patientId: patientId,
        filter: {
          deleted: true
        }
      })
      .then(response => {
        commit('receiveDeleted', response.data.bia)
      })
  },
  restoreExam({ dispatch, rootState, rootGetters }, examId) {
    return this.$axios.post('/fitness-app-backend/v1/app/bia/restore', {
      accessToken: rootState.user.token,
      id: examId
    })
  },
  getExam({ commit, rootState }, examId) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/bia/get', {
        accessToken: rootState.user.token,
        id: examId
      })
      .then(response => {
        response.data.bia.biaId = response.data.bia.id

        commit('receiveExam', response.data.bia)
      })
  },
  createExam({ dispatch, rootState, rootGetters }, exam) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/bia/create', {
        accessToken: rootState.user.token,
        bia: exam
      })
      .then(response => {
        if (!rootGetters['profile/wizard']) {
          dispatch('getList', exam.patientId)
        }
        if (rootState.profile.isBaseline) {
          dispatch('profile/getProfile', exam.patientId, { root: true })
        }
        return response
      })
  },
  updateExam({ dispatch, rootState, rootGetters }, exam) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/bia/update', {
        accessToken: rootState.user.token,
        bia: exam
      })
      .then(() => {
        dispatch('getExam', exam.id)
        if (rootState.profile.isBaseline) {
          dispatch('profile/getProfile', exam.patientId, { root: true })
        }
      })
  },
  deleteExam({ rootState, rootGetters }, examId) {
    return this.$axios.post('/fitness-app-backend/v1/app/bia/delete', {
      accessToken: rootState.user.token,
      id: examId
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
