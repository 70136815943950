import { version } from '../package.json'

const state = () => ({
  group: null,
  patient: null,
  appointment: null,
  exams: null,
  shopLink: null,
  license: null,
  annotations: null
})
const getters = {
  group: state => state.group,
  patient: state => state.patient,
  appointment: state => state.appointment,
  exams: state => state.exams,
  shopLink: state => state.shopLink,
  license: state => state.license,
  annotations: state => state.annotations
}
const mutations = {
  receiveGroup(state, group) {
    state.group = group
  },
  receivePatient(state, patient) {
    state.patient = patient
  },
  receiveAppointment(state, appointment) {
    state.appointment = appointment
  },
  receiveExams(state, exams) {
    state.exams = exams
  },
  receiveShopLink(state, shopLink) {
    state.shopLink = shopLink
  },
  receiveLicense(state, license) {
    state.license = license
  },
  receiveAnnotations(state, annotations) {
    state.annotations = annotations
  }
}
const actions = {
  getDashboard({ commit, rootState }) {
    return this.$axios
      .post('/bodygram-backend/v1/core/home/index', {
        accessToken: rootState.user.token,
        version: window.$nuxt.$platform === 'electron' ? version : null
      })
      .then(response => {
        commit('receiveGroup', response.data.recent.group)
        commit('receivePatient', response.data.recent.patient)
        commit('receiveAppointment', response.data.appointment)
        commit('receiveExams', response.data.exams)
        commit('receiveShopLink', response.data.shopLink)
        commit('receiveLicense', response.data.license)
        commit('receiveAnnotations', response.data.annotations)
      })
  }
}
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
