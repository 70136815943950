<template>
  <div class="container-fluid mt-3">
    <b-breadcrumb
      :class="$route.name.includes('reports-') ? 'breadcrumb-resized' : ''"
      :items="items"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      exam: 'exams/exam',
      group: 'group/group',
      patient: 'patient/patient',
      crumb: 'crumb/crumb',
      bodyscanExam: 'bodyscan/exam'
    }),
    items() {
      const crumbs = [
        {
          html: '<i class="fas fa-home"></i>',
          to: this.localePath('index')
        }
      ]
      if (this.$route.name.includes('users')) {
        crumbs.push({
          text: this.$t('app.users.title'),
          to: this.localePath('users')
        })
      } else if (this.$route.name.includes('schedule')) {
        crumbs.push({
          text: this.$t('app.schedule.schedule'),
          to: this.localePath('schedule')
        })
      } else if (this.$route.name.includes('search')) {
        crumbs.push({
          text: this.$t('app.common.search'),
          to: this.localePath('search')
        })
      } else if (this.$route.name.includes('settings-licenses')) {
        crumbs.push({
          text: this.$t('app.settings.settings'),
          to: this.localePath('settings')
        })
        crumbs.push({
          text: this.$t('app.settings.licenses.breadcrumb'),
          to: this.localePath('settings-licenses')
        })
      } else if (this.$route.name.includes('settings-documents')) {
        crumbs.push({
          text: this.$t('app.settings.settings'),
          to: this.localePath('settings')
        })
        crumbs.push({
          text: this.$t('app.settings.documents.title'),
          to: this.localePath('settings-documents')
        })
      } else if (this.$route.name.includes('settings')) {
        crumbs.push({
          text: this.$t('app.settings.settings'),
          to: this.localePath('settings')
        })
      } else if (this.$route.name.includes('biva')) {
        crumbs.push({
          text: this.$t('biva.bivaProManager'),
          to: this.localePath('biva')
        })
      } else if (this.$route.name.includes('groups')) {
        crumbs.push({
          text: this.$t('app.folders.folders'),
          to: this.localePath('groups')
        })
      } else if (this.$route.name.includes('academy-material')) {
        crumbs.push({
          text: this.$t('app.academy.academy'),
          to: this.localePath('academy')
        })
        crumbs.push({
          text: this.$t('app.academy.material'),
          to: this.localePath('academy-material')
        })
      } else if (this.$route.name.includes('academy')) {
        crumbs.push({
          text: this.$t('app.academy.academy'),
          to: this.localePath('academy')
        })
      }
      if (this.$route.params.groupId) {
        crumbs.push({
          text: this.group ? this.group.name : '',
          to: this.localePath({
            name: 'groups-groupId',
            params: { groupId: this.$route.params.groupId }
          })
        })
      }
      if (this.$route.params.patientId) {
        crumbs.push({
          text: this.patient
            ? this.patient.firstName + ' ' + this.patient.lastName
            : '',
          to: this.localePath({
            name: 'groups-groupId-patients-patientId',
            params: {
              groupId: this.$route.params.groupId,
              patientId: this.$route.params.patientId
            }
          })
        })
      }
      if (this.$route.params.examId) {
        crumbs.push({
          text: this.exam
            ? this.$t('app.exams.testOf') +
              ' ' +
              this.getDateTime(this.exam.effectiveAt)
            : '',
          to: this.localePath({
            name: 'groups-groupId-patients-patientId-exams-examId',
            params: {
              groupId: this.$route.params.groupId,
              patientId: this.$route.params.patientId,
              examId: this.$route.params.examId
            }
          })
        })
      }
      if (
        this.$route.name.includes('reports') &&
        !this.$route.name.includes('bodyscan')
      ) {
        crumbs.push({
          text: this.$t('app.reports.reports'),
          to: this.localePath({
            name: 'groups-groupId-patients-patientId-exams-examId-reports',
            params: {
              groupId: this.$route.params.groupId,
              patientId: this.$route.params.patientId,
              examId: this.$route.params.examId
            }
          })
        })
      }
      if (this.$route.name.includes('bodyscan')) {
        crumbs.push({
          text: 'Bodyscan',
          to: this.localePath({
            name: 'groups-groupId-patients-patientId-bodyscan',
            params: {
              groupId: this.$route.params.groupId,
              patientId: this.$route.params.patientId
            }
          })
        })
        if (this.$route.params.bodyscanExamId) {
          crumbs.push({
            text: this.bodyscanExam
              ? this.$t('app.exams.testOf') +
                ' ' +
                this.getDateTime(this.bodyscanExam.effectiveAt)
              : '',
            to: this.localePath({
              name: 'groups-groupId-patients-patientId-bodyscan-bodyscanExamId',
              params: {
                groupId: this.$route.params.groupId,
                patientId: this.$route.params.patientId,
                bodyscanExamId: this.$route.params.bodyscanExamId
              }
            })
          })
          if (this.$route.name.includes('reports')) {
            crumbs.push({
              text: this.$t('app.reports.reports'),
              to: this.localePath({
                name:
                  'groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports',
                params: {
                  groupId: this.$route.params.groupId,
                  patientId: this.$route.params.patientId,
                  bodyscanExamId: this.$route.params.bodyscanExamId
                }
              })
            })
          }
        }
      }
      if (this.crumb) {
        crumbs.push({
          text: this.crumb
        })
      }
      return crumbs
    }
  },
  created() {
    if (this.$route.params.examId && !this.exam) {
      this.getExam(this.$route.params.examId)
    }
    if (this.$route.params.groupId && !this.group) {
      this.getGroup(this.$route.params.groupId)
    }
    if (this.$route.params.patientId && !this.patient) {
      this.getPatient(this.$route.params.patientId)
    }
    if (this.$route.params.bodyscanExamId && !this.bodyscanExam) {
      this.getBodyscanExam(this.$route.params.bodyscanExamId)
    }
  },
  methods: {
    ...mapActions({
      getExam: 'exams/getExam',
      getGroup: 'group/getGroup',
      getPatient: 'patient/getPatient',
      getBodyscanExam: 'bodyscan/getExam'
    }),
    getDateTime(date) {
      return this.$dayjs(date).format('L LT')
    }
  }
}
</script>
