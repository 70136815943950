const state = () => ({
  groups: null,
  deleted: null
})
const getters = {
  groups: state => state.groups,
  deleted: state => state.deleted,
  allGroups: state => {
    if (state.groups && state.deleted) {
      return state.groups.concat(state.deleted)
    } else if (state.groups) {
      return state.groups
    } else if (state.deleted) {
      return state.deleted
    }
  }
}
const mutations = {
  receiveGroups(state, groups) {
    state.groups = groups
  },
  receiveDeleted(state, groups) {
    state.deleted = groups
  }
}
const actions = {
  getGroups({ commit, rootState }) {
    return this.$axios
      .post('/bodygram-backend/v1/core/groups/list', {
        accessToken: rootState.user.token
      })
      .then(response => {
        commit('receiveGroups', response.data.groups)
      })
  },
  getDeleted({ commit, rootState }) {
    return this.$axios
      .post('/bodygram-backend/v1/core/groups/list', {
        accessToken: rootState.user.token,
        filter: {
          deleted: true
        }
      })
      .then(response => {
        commit('receiveDeleted', response.data.groups)
      })
      .catch(error => console.error(error))
  },
  createGroup({ dispatch, rootState, rootGetters }, group) {
    return this.$axios
      .post('/bodygram-backend/v1/core/groups/create', {
        accessToken: rootState.user.token,
        group: {
          name: group.name,
          description: group.description
        }
      })
      .then(response => {
        if (
          window.$nuxt.$platform === 'electron' &&
          rootGetters['network/onLine']
        ) {
          this.$axios.post(
            `${rootGetters['network/localAPI']}/bodygram-backend/v1/core/groups/create`,
            {
              silent: true,
              id: response.data.group.id,
              group: {
                name: group.name,
                description: group.description
              }
            }
          )
        }
        return response
      })
  },
  restoreGroup({ dispatch, rootState, rootGetters }, groupId) {
    return this.$axios
      .post('/bodygram-backend/v1/core/groups/restore', {
        accessToken: rootState.user.token,
        id: groupId
      })
      .then(() => {
        if (
          window.$nuxt.$platform === 'electron' &&
          rootGetters['network/onLine']
        ) {
          this.$axios.post(
            `${rootGetters['network/localAPI']}/bodygram-backend/v1/core/groups/restore`,
            {
              silent: true,
              id: groupId
            }
          )
        }
        dispatch('getGroups')
      })
  }
}
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
