const state = () => ({
  patients: null,
  deleted: null,
  all: null
})
const getters = {
  patients: state => state.patients,
  deleted: state => state.deleted,
  all: state => state.all
}
const mutations = {
  receivePatients(state, patients) {
    state.patients = patients
  },
  receiveDeleted(state, patients) {
    state.deleted = patients
  },
  receiveAll(state, patients) {
    state.all = patients
  }
}
const actions = {
  getPatients({ commit, rootState }, { groupId, patientsLimit }) {
    return this.$axios
      .post('/bodygram-backend/v1/core/patients/list', {
        accessToken: rootState.user.token,
        filters: {
          query: null,
          groupId: groupId
        },
        offset: 0,
        limit: patientsLimit
      })
      .then(response => {
        commit('receivePatients', response.data.patients)
      })
  },
  searchPatients({ commit, rootState }, query) {
    return this.$axios
      .post('/bodygram-backend/v1/core/patients/list', {
        accessToken: rootState.user.token,
        filters: {
          query: query
        },
        offset: 0
      })
      .then(response => {
        commit('receivePatients', response.data.patients)
      })
  },
  getAll({ commit, rootState }) {
    return this.$axios
      .post('/bodygram-backend/v1/core/patients/list', {
        accessToken: rootState.user.token
      })
      .then(response => {
        commit('receiveAll', response.data.patients)
      })
  },
  getDeleted({ commit, rootState }, groupId) {
    return this.$axios
      .post('/bodygram-backend/v1/core/patients/list', {
        accessToken: rootState.user.token,
        filters: {
          query: null,
          groupId: groupId,
          deleted: true
        },
        offset: 0
      })
      .then(response => {
        commit('receiveDeleted', response.data.patients)
      })
  },
  restorePatient({ dispatch, rootState, rootGetters }, patientId) {
    return this.$axios
      .post('/bodygram-backend/v1/core/patients/restore', {
        accessToken: rootState.user.token,
        id: patientId
      })
      .then(() => {
        if (
          window.$nuxt.$platform === 'electron' &&
          rootGetters['network/onLine']
        ) {
          this.$axios.post(
            `${rootGetters['network/localAPI']}/bodygram-backend/v1/core/patients/restore`,
            {
              silent: true,
              id: patientId
            }
          )
        }
        dispatch('getPatients')
      })
  }
}
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
