export default function(actionName, arg) {
  return new Promise((resolve, reject) => {
    const ipcRenderer = window.ipcRenderer || window.parent.ipcRenderer

    ipcRenderer.send(actionName, arg)
    ipcRenderer.on(`${actionName}Reply`, (event, reply) => {
      ipcRenderer.removeAllListeners(`${actionName}Reply`)
      ipcRenderer.removeAllListeners(`${actionName}Error`)
      resolve(reply)
    })
    ipcRenderer.on(`${actionName}Error`, (event, error) => {
      ipcRenderer.removeAllListeners(`${actionName}Reply`)
      ipcRenderer.removeAllListeners(`${actionName}Error`)
      reject(error)
    })
  })
}
