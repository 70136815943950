import Vue from 'vue'
import bugsnag from '@bugsnag/js'
import bugsnagVue from '@bugsnag/plugin-vue'
import { version } from '../package'

let bugsnagClient = {}

if (
  process.env.releaseStage === 'production' &&
  process.env.platform === 'web'
) {
  bugsnagClient = bugsnag({
    apiKey: '329763226d91d2fcdd45e19e6bcc8948',
    appVersion: version,
    releaseStage: process.env.releaseStage,
    notifyReleaseStages: ['production']
  })
  bugsnagClient.use(bugsnagVue, Vue)
}

export default function({ app }, inject) {
  inject('bugsnag', bugsnagClient)
}
