const state = () => ({
  list: null,
  exam: null
})

const getters = {
  list: state => state.list,
  exam: state => state.exam,
  leftSum: state => {
    if (state.exam) {
      return (
        state.exam.tricipitalLeft +
        state.exam.bicipitalLeft +
        state.exam.subscapularLeft +
        state.exam.pectoralLeft +
        state.exam.axillaryLeft +
        state.exam.supraspinalLeft +
        state.exam.abdominalLeft +
        state.exam.legLeft +
        state.exam.calfLeft
      ).toFixed(2)
    } else {
      return null
    }
  },
  rightSum: state => {
    if (state.exam) {
      return (
        state.exam.tricipitalRight +
        state.exam.bicipitalRight +
        state.exam.subscapularRight +
        state.exam.pectoralRight +
        state.exam.axillaryRight +
        state.exam.supraspinalRight +
        state.exam.abdominalRight +
        state.exam.legRight +
        state.exam.calfRight
      ).toFixed(2)
    } else {
      return null
    }
  }
}

const mutations = {
  receiveList(state, list) {
    state.list = list
  },
  receiveExam(state, exam) {
    state.exam = exam
  }
}

const actions = {
  getList({ commit, rootState }, patientId) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/skinfolds/list', {
        accessToken: rootState.user.token,
        patientId: patientId,
        filters: {
          singleOnly: true
        }
      })
      .then(response => {
        commit('receiveList', response.data.skinfolds)
      })
  },
  getExam({ commit, rootState }, examId) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/skinfolds/get', {
        accessToken: rootState.user.token,
        id: examId
      })
      .then(response => {
        commit('receiveExam', response.data.skinfold)
      })
  },
  createExam({ dispatch, rootState, rootGetters }, exam) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/skinfolds/create', {
        accessToken: rootState.user.token,
        skinfold: exam
      })
      .then(response => {
        if (!rootGetters['profile/wizard']) {
          dispatch('getList', exam.patientId)
        }
        if (rootState.profile.isBaseline) {
          dispatch('profile/getProfile', exam.patientId, { root: true })
        }
        return response
      })
  },
  updateExam({ dispatch, rootState }, exam) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/skinfolds/update', {
        accessToken: rootState.user.token,
        skinfold: exam
      })
      .then(() => {
        dispatch('getExam', exam.id)
        if (rootState.profile.isBaseline) {
          dispatch('profile/getProfile', exam.patientId, { root: true })
        }
      })
  },
  deleteExam({ rootState }, examId) {
    return this.$axios.post('/fitness-app-backend/v1/app/skinfolds/delete', {
      accessToken: rootState.user.token,
      id: examId
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
