import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6fce3fcf = () => interopDefault(import('../pages/academy/index.vue' /* webpackChunkName: "pages/academy/index" */))
const _64b2f850 = () => interopDefault(import('../pages/accept.vue' /* webpackChunkName: "pages/accept" */))
const _b72eb3d2 = () => interopDefault(import('../pages/accept-contract.vue' /* webpackChunkName: "pages/accept-contract" */))
const _4409af2f = () => interopDefault(import('../pages/admin.vue' /* webpackChunkName: "pages/admin" */))
const _4476ba05 = () => interopDefault(import('../pages/biva/index.vue' /* webpackChunkName: "pages/biva/index" */))
const _795d8711 = () => interopDefault(import('../pages/bluetooth/index.vue' /* webpackChunkName: "pages/bluetooth/index" */))
const _4858a512 = () => interopDefault(import('../pages/config.vue' /* webpackChunkName: "pages/config" */))
const _6f4aa872 = () => interopDefault(import('../pages/customer-signup.vue' /* webpackChunkName: "pages/customer-signup" */))
const _129a5b32 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7b4b3bcb = () => interopDefault(import('../pages/fitness/index.vue' /* webpackChunkName: "pages/fitness/index" */))
const _04942c73 = () => interopDefault(import('../pages/forgot.vue' /* webpackChunkName: "pages/forgot" */))
const _783238e5 = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _767e5e27 = () => interopDefault(import('../pages/groups/index.vue' /* webpackChunkName: "pages/groups/index" */))
const _68aa9bc0 = () => interopDefault(import('../pages/HBO-signup.vue' /* webpackChunkName: "pages/HBO-signup" */))
const _df559b30 = () => interopDefault(import('../pages/keto/index.vue' /* webpackChunkName: "pages/keto/index" */))
const _477c036e = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _46277ab2 = () => interopDefault(import('../pages/regional/index.vue' /* webpackChunkName: "pages/regional/index" */))
const _89466da2 = () => interopDefault(import('../pages/reset.vue' /* webpackChunkName: "pages/reset" */))
const _0d16d3e7 = () => interopDefault(import('../pages/schedule.vue' /* webpackChunkName: "pages/schedule" */))
const _27203710 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _50c73bb6 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _0f509030 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _cd17dffa = () => interopDefault(import('../pages/update-required.vue' /* webpackChunkName: "pages/update-required" */))
const _fec5e42a = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _3ca26bbd = () => interopDefault(import('../pages/academy/material/index.vue' /* webpackChunkName: "pages/academy/material/index" */))
const _04a54f6e = () => interopDefault(import('../pages/fitness/groups/index.vue' /* webpackChunkName: "pages/fitness/groups/index" */))
const _7c9a6eae = () => interopDefault(import('../pages/groups/deleted.vue' /* webpackChunkName: "pages/groups/deleted" */))
const _0c99c39e = () => interopDefault(import('../pages/keto/groups/index.vue' /* webpackChunkName: "pages/keto/groups/index" */))
const _615262b2 = () => interopDefault(import('../pages/regional/groups/index.vue' /* webpackChunkName: "pages/regional/groups/index" */))
const _37f07c9c = () => interopDefault(import('../pages/settings/documents.vue' /* webpackChunkName: "pages/settings/documents" */))
const _dd43ddc4 = () => interopDefault(import('../pages/settings/licenses.vue' /* webpackChunkName: "pages/settings/licenses" */))
const _1cca2a35 = () => interopDefault(import('../pages/fitness/groups/deleted.vue' /* webpackChunkName: "pages/fitness/groups/deleted" */))
const _059f5438 = () => interopDefault(import('../pages/keto/groups/deleted.vue' /* webpackChunkName: "pages/keto/groups/deleted" */))
const _0118bf2e = () => interopDefault(import('../pages/regional/groups/deleted.vue' /* webpackChunkName: "pages/regional/groups/deleted" */))
const _2fb738b5 = () => interopDefault(import('../pages/biva/regional/_sessionId.vue' /* webpackChunkName: "pages/biva/regional/_sessionId" */))
const _5397a277 = () => interopDefault(import('../pages/biva/sessions/_sessionId.vue' /* webpackChunkName: "pages/biva/sessions/_sessionId" */))
const _408b98dc = () => interopDefault(import('../pages/fitness/groups/_groupId/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/index" */))
const _7645502f = () => interopDefault(import('../pages/keto/groups/_groupId/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/index" */))
const _5fa1c479 = () => interopDefault(import('../pages/regional/groups/_groupId/index.vue' /* webpackChunkName: "pages/regional/groups/_groupId/index" */))
const _f392d54e = () => interopDefault(import('../pages/fitness/groups/_groupId/deleted.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/deleted" */))
const _b3260294 = () => interopDefault(import('../pages/keto/groups/_groupId/deleted.vue' /* webpackChunkName: "pages/keto/groups/_groupId/deleted" */))
const _ab04ef00 = () => interopDefault(import('../pages/regional/groups/_groupId/deleted.vue' /* webpackChunkName: "pages/regional/groups/_groupId/deleted" */))
const _339b0c36 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/index" */))
const _3de62602 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/index" */))
const _5690dd68 = () => interopDefault(import('../pages/regional/groups/_groupId/patients/_patientId/index.vue' /* webpackChunkName: "pages/regional/groups/_groupId/patients/_patientId/index" */))
const _64e7c2bc = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/somatotype.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/somatotype" */))
const _00c07ca5 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/somatotype.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/somatotype" */))
const _72b8d551 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/deleted.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/deleted" */))
const _2dc1146e = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/deleted.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/deleted" */))
const _4c80d490 = () => interopDefault(import('../pages/regional/groups/_groupId/patients/_patientId/exams/deleted.vue' /* webpackChunkName: "pages/regional/groups/_groupId/patients/_patientId/exams/deleted" */))
const _234d372a = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/index" */))
const _cf9c3a12 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/cardio/_cardioId/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/cardio/_cardioId/index" */))
const _0904d06a = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/circumference/_circumferenceId/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/circumference/_circumferenceId/index" */))
const _b1b0d498 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/index" */))
const _98ab1faa = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/fitness/_fitnessId/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/fitness/_fitnessId/index" */))
const _63f87158 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/skinfolds/_skinfoldId/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/skinfolds/_skinfoldId/index" */))
const _15fff458 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/trainings/_trainingId/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/trainings/_trainingId/index" */))
const _1301f42e = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/index" */))
const _f55b570c = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/cardio/_cardioId/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/cardio/_cardioId/index" */))
const _45cba80e = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/circumference/_circumferenceId/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/circumference/_circumferenceId/index" */))
const _ba4d69de = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/index" */))
const _4b16e624 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/fitness/_fitnessId/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/fitness/_fitnessId/index" */))
const _6fe6609e = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/skinfolds/_skinfoldId/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/skinfolds/_skinfoldId/index" */))
const _21ede39e = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/trainings/_trainingId/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/trainings/_trainingId/index" */))
const _26c02cdb = () => interopDefault(import('../pages/regional/groups/_groupId/patients/_patientId/exams/_examId/index.vue' /* webpackChunkName: "pages/regional/groups/_groupId/patients/_patientId/exams/_examId/index" */))
const _3dc3f0d3 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/bia.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/bia" */))
const _0535f629 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/fitnessBia.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/fitnessBia" */))
const _6e92f02b = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/fluidRetention.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/fluidRetention" */))
const _c5fb48b8 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/qualitative.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/qualitative" */))
const _533d3e64 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/quantitative.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/quantitative" */))
const _73a6071b = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/reports/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/reports/index" */))
const _22ac0596 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/cardio/_cardioId/hr.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/cardio/_cardioId/hr" */))
const _37637002 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/circumference/_circumferenceId/radar.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/circumference/_circumferenceId/radar" */))
const _6c603f48 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/bia.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/bia" */))
const _4a1bb776 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/circumferenceRadar.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/circumferenceRadar" */))
const _919fab00 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/fitnessBia.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/fitnessBia" */))
const _751d2102 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/fluidRetention.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/fluidRetention" */))
const _41f9ee11 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/functional.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/functional" */))
const _29ee8b98 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/hr.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/hr" */))
const _8194e018 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/load.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/load" */))
const _2a4eb22d = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/qualitative.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/qualitative" */))
const _1ac04936 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/quantitative.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/quantitative" */))
const _41924138 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/reports/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/reports/index" */))
const _a1efe2ec = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/skinfoldRadar.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/skinfoldRadar" */))
const _5c69e79a = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/somatotype.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/somatotype" */))
const _046f9234 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/zscore.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/zscore" */))
const _fb7ff40c = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/fitness/_fitnessId/functional.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/fitness/_fitnessId/functional" */))
const _925710f0 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/skinfolds/_skinfoldId/radar.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/skinfolds/_skinfoldId/radar" */))
const _4b02c858 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/trainings/_trainingId/load.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/trainings/_trainingId/load" */))
const _3878d554 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/bia.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/bia" */))
const _49606f74 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/fitnessBia.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/fitnessBia" */))
const _210c6bc8 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/fluidRetention.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/fluidRetention" */))
const _ebba65b2 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/qualitative.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/qualitative" */))
const _e561c0aa = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/quantitative.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/quantitative" */))
const _cb1fb844 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/reports/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/reports/index" */))
const _2b489adc = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/cardio/_cardioId/hr.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/cardio/_cardioId/hr" */))
const _2e9c5842 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/circumference/_circumferenceId/radar.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/circumference/_circumferenceId/radar" */))
const _3a3948f9 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/bia.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/bia" */))
const _0400ce08 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/circumferenceRadar.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/circumferenceRadar" */))
const _0ab5928a = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/estimates.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/estimates" */))
const _440b717a = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/fitnessBia.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/fitnessBia" */))
const _8795b776 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/fluidRetention.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/fluidRetention" */))
const _68c40ad4 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/functional.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/functional" */))
const _4e971b5b = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/hr.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/hr" */))
const _293bdac6 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/ketone.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/ketone" */))
const _5bf93af7 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/load.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/load" */))
const _466fa46c = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/qualitative.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/qualitative" */))
const _e1545930 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/quantitative.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/quantitative" */))
const _4d80307e = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/reports/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/reports/index" */))
const _0ed5ae14 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/somatotype.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/somatotype" */))
const _8dc1f2e8 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/weightLoss.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/weightLoss" */))
const _784d2d29 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/zscore.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/zscore" */))
const _1e286b17 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/fitness/_fitnessId/functional.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/fitness/_fitnessId/functional" */))
const _9e450036 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/skinfolds/_skinfoldId/radar.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/skinfolds/_skinfoldId/radar" */))
const _773493d7 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/trainings/_trainingId/load.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/trainings/_trainingId/load" */))
const _55bbd57a = () => interopDefault(import('../pages/regional/groups/_groupId/patients/_patientId/exams/_examId/bia.vue' /* webpackChunkName: "pages/regional/groups/_groupId/patients/_patientId/exams/_examId/bia" */))
const _3ab55f8b = () => interopDefault(import('../pages/regional/groups/_groupId/patients/_patientId/exams/_examId/reports/index.vue' /* webpackChunkName: "pages/regional/groups/_groupId/patients/_patientId/exams/_examId/reports/index" */))
const _ef1303ec = () => interopDefault(import('../pages/regional/groups/_groupId/patients/_patientId/exams/_examId/vector.vue' /* webpackChunkName: "pages/regional/groups/_groupId/patients/_patientId/exams/_examId/vector" */))
const _52938e32 = () => interopDefault(import('../pages/regional/groups/_groupId/patients/_patientId/histories/_historyId/attachments/index.vue' /* webpackChunkName: "pages/regional/groups/_groupId/patients/_patientId/histories/_historyId/attachments/index" */))
const _44cf2c6a = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/reports/_reportSlug/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/reports/_reportSlug/index" */))
const _6938df33 = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/index.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/index" */))
const _e1c586a6 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/reports/_reportSlug/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/reports/_reportSlug/index" */))
const _5b8f4410 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/index.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/index" */))
const _50ed5cda = () => interopDefault(import('../pages/regional/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/index.vue' /* webpackChunkName: "pages/regional/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/index" */))
const _7dcb793c = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/reports/_reportSlug/iframe.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/bia/_biaId/reports/_reportSlug/iframe" */))
const _32cfc0da = () => interopDefault(import('../pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/iframe.vue' /* webpackChunkName: "pages/fitness/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/iframe" */))
const _7d3f7159 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/reports/_reportSlug/iframe.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/bia/_biaId/reports/_reportSlug/iframe" */))
const _3f0e5656 = () => interopDefault(import('../pages/keto/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/iframe.vue' /* webpackChunkName: "pages/keto/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/iframe" */))
const _15195268 = () => interopDefault(import('../pages/regional/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/iframe.vue' /* webpackChunkName: "pages/regional/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportSlug/iframe" */))
const _0178b60e = () => interopDefault(import('../pages/groups/_groupId/index.vue' /* webpackChunkName: "pages/groups/_groupId/index" */))
const _59c0fce1 = () => interopDefault(import('../pages/groups/_groupId/bia.vue' /* webpackChunkName: "pages/groups/_groupId/bia" */))
const _2dad6e00 = () => interopDefault(import('../pages/groups/_groupId/deleted.vue' /* webpackChunkName: "pages/groups/_groupId/deleted" */))
const _7d2431cc = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/index" */))
const _68ea83b4 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/activities/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/activities/index" */))
const _46a14a58 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/bodyscan/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/bodyscan/index" */))
const _c6c6b188 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/grid.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/grid" */))
const _0b7b3f0d = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/schedule.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/schedule" */))
const _fbd765a0 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/bodyscan/grid.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/bodyscan/grid" */))
const _3dc3b638 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/deleted.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/deleted" */))
const _2d4208c1 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/bodyscan/_bodyscanExamId/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/bodyscan/_bodyscanExamId/index" */))
const _9b6c254a = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/index" */))
const _02124516 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/overview/_formulaId/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/overview/_formulaId/index" */))
const _1eb0151e = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/bodyscan/_bodyscanExamId/reports/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/bodyscan/_bodyscanExamId/reports/index" */))
const _6121f5c3 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/bia.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/bia" */))
const _5dd00058 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/estimates.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/estimates" */))
const _acc5612a = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/hydra.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/hydra" */))
const _120a41b2 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/interpretive.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/interpretive" */))
const _fa2e6b48 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/measures.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/measures" */))
const _a4dafec2 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/percentiles.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/percentiles" */))
const _ba92bfea = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/reports/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/reports/index" */))
const _6e7bd5e1 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/reports/edit.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/reports/edit" */))
const _b3b6a1e8 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/reports/default/_reportSlug/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/reports/default/_reportSlug/index" */))
const _47264ada = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/reports/default/_reportSlug/iframe.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/reports/default/_reportSlug/iframe" */))
const _14d11746 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/reports/default/_reportSlug/new/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/reports/default/_reportSlug/new/index" */))
const _386049c0 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/bodyscan/_bodyscanExamId/reports/_reportSlug/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/bodyscan/_bodyscanExamId/reports/_reportSlug/index" */))
const _5358f8ca = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportId/index.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportId/index" */))
const _0743f2b4 = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/bodyscan/_bodyscanExamId/reports/_reportSlug/iframe.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/bodyscan/_bodyscanExamId/reports/_reportSlug/iframe" */))
const _407b38dc = () => interopDefault(import('../pages/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportId/iframe.vue' /* webpackChunkName: "pages/groups/_groupId/patients/_patientId/exams/_examId/reports/_reportId/iframe" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/academy",
    component: _6fce3fcf,
    name: "academy___en"
  }, {
    path: "/accept",
    component: _64b2f850,
    name: "accept___en"
  }, {
    path: "/accept-contract",
    component: _b72eb3d2,
    name: "accept-contract___en"
  }, {
    path: "/admin",
    component: _4409af2f,
    name: "admin___en"
  }, {
    path: "/biva",
    component: _4476ba05,
    name: "biva___en"
  }, {
    path: "/bluetooth",
    component: _795d8711,
    name: "bluetooth___en"
  }, {
    path: "/config",
    component: _4858a512,
    name: "config___en"
  }, {
    path: "/customer-signup",
    component: _6f4aa872,
    name: "customer-signup___en"
  }, {
    path: "/de",
    component: _129a5b32,
    name: "index___de"
  }, {
    path: "/en-gb",
    component: _129a5b32,
    name: "index___en-gb"
  }, {
    path: "/es",
    component: _129a5b32,
    name: "index___es"
  }, {
    path: "/fitness",
    component: _7b4b3bcb,
    name: "fitness___en"
  }, {
    path: "/forgot",
    component: _04942c73,
    name: "forgot___en"
  }, {
    path: "/forgot-password",
    component: _783238e5,
    name: "forgot-password___en"
  }, {
    path: "/fr",
    component: _129a5b32,
    name: "index___fr"
  }, {
    path: "/groups",
    component: _767e5e27,
    name: "groups___en"
  }, {
    path: "/HBO-signup",
    component: _68aa9bc0,
    name: "HBO-signup___en"
  }, {
    path: "/it",
    component: _129a5b32,
    name: "index___it"
  }, {
    path: "/keto",
    component: _df559b30,
    name: "keto___en"
  }, {
    path: "/login",
    component: _477c036e,
    name: "login___en"
  }, {
    path: "/nl",
    component: _129a5b32,
    name: "index___nl"
  }, {
    path: "/nl-be",
    component: _129a5b32,
    name: "index___nl-be"
  }, {
    path: "/pl",
    component: _129a5b32,
    name: "index___pl"
  }, {
    path: "/regional",
    component: _46277ab2,
    name: "regional___en"
  }, {
    path: "/reset",
    component: _89466da2,
    name: "reset___en"
  }, {
    path: "/schedule",
    component: _0d16d3e7,
    name: "schedule___en"
  }, {
    path: "/search",
    component: _27203710,
    name: "search___en"
  }, {
    path: "/settings",
    component: _50c73bb6,
    name: "settings___en"
  }, {
    path: "/signup",
    component: _0f509030,
    name: "signup___en"
  }, {
    path: "/update-required",
    component: _cd17dffa,
    name: "update-required___en"
  }, {
    path: "/users",
    component: _fec5e42a,
    name: "users___en"
  }, {
    path: "/zh",
    component: _129a5b32,
    name: "index___zh"
  }, {
    path: "/academy/material",
    component: _3ca26bbd,
    name: "academy-material___en"
  }, {
    path: "/de/academy",
    component: _6fce3fcf,
    name: "academy___de"
  }, {
    path: "/de/accept",
    component: _64b2f850,
    name: "accept___de"
  }, {
    path: "/de/accept-contract",
    component: _b72eb3d2,
    name: "accept-contract___de"
  }, {
    path: "/de/admin",
    component: _4409af2f,
    name: "admin___de"
  }, {
    path: "/de/biva",
    component: _4476ba05,
    name: "biva___de"
  }, {
    path: "/de/bluetooth",
    component: _795d8711,
    name: "bluetooth___de"
  }, {
    path: "/de/config",
    component: _4858a512,
    name: "config___de"
  }, {
    path: "/de/customer-signup",
    component: _6f4aa872,
    name: "customer-signup___de"
  }, {
    path: "/de/fitness",
    component: _7b4b3bcb,
    name: "fitness___de"
  }, {
    path: "/de/forgot",
    component: _04942c73,
    name: "forgot___de"
  }, {
    path: "/de/forgot-password",
    component: _783238e5,
    name: "forgot-password___de"
  }, {
    path: "/de/groups",
    component: _767e5e27,
    name: "groups___de"
  }, {
    path: "/de/HBO-signup",
    component: _68aa9bc0,
    name: "HBO-signup___de"
  }, {
    path: "/de/keto",
    component: _df559b30,
    name: "keto___de"
  }, {
    path: "/de/login",
    component: _477c036e,
    name: "login___de"
  }, {
    path: "/de/regional",
    component: _46277ab2,
    name: "regional___de"
  }, {
    path: "/de/reset",
    component: _89466da2,
    name: "reset___de"
  }, {
    path: "/de/schedule",
    component: _0d16d3e7,
    name: "schedule___de"
  }, {
    path: "/de/search",
    component: _27203710,
    name: "search___de"
  }, {
    path: "/de/settings",
    component: _50c73bb6,
    name: "settings___de"
  }, {
    path: "/de/signup",
    component: _0f509030,
    name: "signup___de"
  }, {
    path: "/de/update-required",
    component: _cd17dffa,
    name: "update-required___de"
  }, {
    path: "/de/users",
    component: _fec5e42a,
    name: "users___de"
  }, {
    path: "/en-gb/academy",
    component: _6fce3fcf,
    name: "academy___en-gb"
  }, {
    path: "/en-gb/accept",
    component: _64b2f850,
    name: "accept___en-gb"
  }, {
    path: "/en-gb/accept-contract",
    component: _b72eb3d2,
    name: "accept-contract___en-gb"
  }, {
    path: "/en-gb/admin",
    component: _4409af2f,
    name: "admin___en-gb"
  }, {
    path: "/en-gb/biva",
    component: _4476ba05,
    name: "biva___en-gb"
  }, {
    path: "/en-gb/bluetooth",
    component: _795d8711,
    name: "bluetooth___en-gb"
  }, {
    path: "/en-gb/config",
    component: _4858a512,
    name: "config___en-gb"
  }, {
    path: "/en-gb/customer-signup",
    component: _6f4aa872,
    name: "customer-signup___en-gb"
  }, {
    path: "/en-gb/fitness",
    component: _7b4b3bcb,
    name: "fitness___en-gb"
  }, {
    path: "/en-gb/forgot",
    component: _04942c73,
    name: "forgot___en-gb"
  }, {
    path: "/en-gb/forgot-password",
    component: _783238e5,
    name: "forgot-password___en-gb"
  }, {
    path: "/en-gb/groups",
    component: _767e5e27,
    name: "groups___en-gb"
  }, {
    path: "/en-gb/HBO-signup",
    component: _68aa9bc0,
    name: "HBO-signup___en-gb"
  }, {
    path: "/en-gb/keto",
    component: _df559b30,
    name: "keto___en-gb"
  }, {
    path: "/en-gb/login",
    component: _477c036e,
    name: "login___en-gb"
  }, {
    path: "/en-gb/regional",
    component: _46277ab2,
    name: "regional___en-gb"
  }, {
    path: "/en-gb/reset",
    component: _89466da2,
    name: "reset___en-gb"
  }, {
    path: "/en-gb/schedule",
    component: _0d16d3e7,
    name: "schedule___en-gb"
  }, {
    path: "/en-gb/search",
    component: _27203710,
    name: "search___en-gb"
  }, {
    path: "/en-gb/settings",
    component: _50c73bb6,
    name: "settings___en-gb"
  }, {
    path: "/en-gb/signup",
    component: _0f509030,
    name: "signup___en-gb"
  }, {
    path: "/en-gb/update-required",
    component: _cd17dffa,
    name: "update-required___en-gb"
  }, {
    path: "/en-gb/users",
    component: _fec5e42a,
    name: "users___en-gb"
  }, {
    path: "/es/academy",
    component: _6fce3fcf,
    name: "academy___es"
  }, {
    path: "/es/accept",
    component: _64b2f850,
    name: "accept___es"
  }, {
    path: "/es/accept-contract",
    component: _b72eb3d2,
    name: "accept-contract___es"
  }, {
    path: "/es/admin",
    component: _4409af2f,
    name: "admin___es"
  }, {
    path: "/es/biva",
    component: _4476ba05,
    name: "biva___es"
  }, {
    path: "/es/bluetooth",
    component: _795d8711,
    name: "bluetooth___es"
  }, {
    path: "/es/config",
    component: _4858a512,
    name: "config___es"
  }, {
    path: "/es/customer-signup",
    component: _6f4aa872,
    name: "customer-signup___es"
  }, {
    path: "/es/fitness",
    component: _7b4b3bcb,
    name: "fitness___es"
  }, {
    path: "/es/forgot",
    component: _04942c73,
    name: "forgot___es"
  }, {
    path: "/es/forgot-password",
    component: _783238e5,
    name: "forgot-password___es"
  }, {
    path: "/es/groups",
    component: _767e5e27,
    name: "groups___es"
  }, {
    path: "/es/HBO-signup",
    component: _68aa9bc0,
    name: "HBO-signup___es"
  }, {
    path: "/es/keto",
    component: _df559b30,
    name: "keto___es"
  }, {
    path: "/es/login",
    component: _477c036e,
    name: "login___es"
  }, {
    path: "/es/regional",
    component: _46277ab2,
    name: "regional___es"
  }, {
    path: "/es/reset",
    component: _89466da2,
    name: "reset___es"
  }, {
    path: "/es/schedule",
    component: _0d16d3e7,
    name: "schedule___es"
  }, {
    path: "/es/search",
    component: _27203710,
    name: "search___es"
  }, {
    path: "/es/settings",
    component: _50c73bb6,
    name: "settings___es"
  }, {
    path: "/es/signup",
    component: _0f509030,
    name: "signup___es"
  }, {
    path: "/es/update-required",
    component: _cd17dffa,
    name: "update-required___es"
  }, {
    path: "/es/users",
    component: _fec5e42a,
    name: "users___es"
  }, {
    path: "/fitness/groups",
    component: _04a54f6e,
    name: "fitness-groups___en"
  }, {
    path: "/fr/academy",
    component: _6fce3fcf,
    name: "academy___fr"
  }, {
    path: "/fr/accept",
    component: _64b2f850,
    name: "accept___fr"
  }, {
    path: "/fr/accept-contract",
    component: _b72eb3d2,
    name: "accept-contract___fr"
  }, {
    path: "/fr/admin",
    component: _4409af2f,
    name: "admin___fr"
  }, {
    path: "/fr/biva",
    component: _4476ba05,
    name: "biva___fr"
  }, {
    path: "/fr/bluetooth",
    component: _795d8711,
    name: "bluetooth___fr"
  }, {
    path: "/fr/config",
    component: _4858a512,
    name: "config___fr"
  }, {
    path: "/fr/customer-signup",
    component: _6f4aa872,
    name: "customer-signup___fr"
  }, {
    path: "/fr/fitness",
    component: _7b4b3bcb,
    name: "fitness___fr"
  }, {
    path: "/fr/forgot",
    component: _04942c73,
    name: "forgot___fr"
  }, {
    path: "/fr/forgot-password",
    component: _783238e5,
    name: "forgot-password___fr"
  }, {
    path: "/fr/groups",
    component: _767e5e27,
    name: "groups___fr"
  }, {
    path: "/fr/HBO-signup",
    component: _68aa9bc0,
    name: "HBO-signup___fr"
  }, {
    path: "/fr/keto",
    component: _df559b30,
    name: "keto___fr"
  }, {
    path: "/fr/login",
    component: _477c036e,
    name: "login___fr"
  }, {
    path: "/fr/regional",
    component: _46277ab2,
    name: "regional___fr"
  }, {
    path: "/fr/reset",
    component: _89466da2,
    name: "reset___fr"
  }, {
    path: "/fr/schedule",
    component: _0d16d3e7,
    name: "schedule___fr"
  }, {
    path: "/fr/search",
    component: _27203710,
    name: "search___fr"
  }, {
    path: "/fr/settings",
    component: _50c73bb6,
    name: "settings___fr"
  }, {
    path: "/fr/signup",
    component: _0f509030,
    name: "signup___fr"
  }, {
    path: "/fr/update-required",
    component: _cd17dffa,
    name: "update-required___fr"
  }, {
    path: "/fr/users",
    component: _fec5e42a,
    name: "users___fr"
  }, {
    path: "/groups/deleted",
    component: _7c9a6eae,
    name: "groups-deleted___en"
  }, {
    path: "/it/academy",
    component: _6fce3fcf,
    name: "academy___it"
  }, {
    path: "/it/accept",
    component: _64b2f850,
    name: "accept___it"
  }, {
    path: "/it/accept-contract",
    component: _b72eb3d2,
    name: "accept-contract___it"
  }, {
    path: "/it/admin",
    component: _4409af2f,
    name: "admin___it"
  }, {
    path: "/it/biva",
    component: _4476ba05,
    name: "biva___it"
  }, {
    path: "/it/bluetooth",
    component: _795d8711,
    name: "bluetooth___it"
  }, {
    path: "/it/config",
    component: _4858a512,
    name: "config___it"
  }, {
    path: "/it/customer-signup",
    component: _6f4aa872,
    name: "customer-signup___it"
  }, {
    path: "/it/fitness",
    component: _7b4b3bcb,
    name: "fitness___it"
  }, {
    path: "/it/forgot",
    component: _04942c73,
    name: "forgot___it"
  }, {
    path: "/it/forgot-password",
    component: _783238e5,
    name: "forgot-password___it"
  }, {
    path: "/it/groups",
    component: _767e5e27,
    name: "groups___it"
  }, {
    path: "/it/HBO-signup",
    component: _68aa9bc0,
    name: "HBO-signup___it"
  }, {
    path: "/it/keto",
    component: _df559b30,
    name: "keto___it"
  }, {
    path: "/it/login",
    component: _477c036e,
    name: "login___it"
  }, {
    path: "/it/regional",
    component: _46277ab2,
    name: "regional___it"
  }, {
    path: "/it/reset",
    component: _89466da2,
    name: "reset___it"
  }, {
    path: "/it/schedule",
    component: _0d16d3e7,
    name: "schedule___it"
  }, {
    path: "/it/search",
    component: _27203710,
    name: "search___it"
  }, {
    path: "/it/settings",
    component: _50c73bb6,
    name: "settings___it"
  }, {
    path: "/it/signup",
    component: _0f509030,
    name: "signup___it"
  }, {
    path: "/it/update-required",
    component: _cd17dffa,
    name: "update-required___it"
  }, {
    path: "/it/users",
    component: _fec5e42a,
    name: "users___it"
  }, {
    path: "/keto/groups",
    component: _0c99c39e,
    name: "keto-groups___en"
  }, {
    path: "/nl-be/academy",
    component: _6fce3fcf,
    name: "academy___nl-be"
  }, {
    path: "/nl-be/accept",
    component: _64b2f850,
    name: "accept___nl-be"
  }, {
    path: "/nl-be/accept-contract",
    component: _b72eb3d2,
    name: "accept-contract___nl-be"
  }, {
    path: "/nl-be/admin",
    component: _4409af2f,
    name: "admin___nl-be"
  }, {
    path: "/nl-be/biva",
    component: _4476ba05,
    name: "biva___nl-be"
  }, {
    path: "/nl-be/bluetooth",
    component: _795d8711,
    name: "bluetooth___nl-be"
  }, {
    path: "/nl-be/config",
    component: _4858a512,
    name: "config___nl-be"
  }, {
    path: "/nl-be/customer-signup",
    component: _6f4aa872,
    name: "customer-signup___nl-be"
  }, {
    path: "/nl-be/fitness",
    component: _7b4b3bcb,
    name: "fitness___nl-be"
  }, {
    path: "/nl-be/forgot",
    component: _04942c73,
    name: "forgot___nl-be"
  }, {
    path: "/nl-be/forgot-password",
    component: _783238e5,
    name: "forgot-password___nl-be"
  }, {
    path: "/nl-be/groups",
    component: _767e5e27,
    name: "groups___nl-be"
  }, {
    path: "/nl-be/HBO-signup",
    component: _68aa9bc0,
    name: "HBO-signup___nl-be"
  }, {
    path: "/nl-be/keto",
    component: _df559b30,
    name: "keto___nl-be"
  }, {
    path: "/nl-be/login",
    component: _477c036e,
    name: "login___nl-be"
  }, {
    path: "/nl-be/regional",
    component: _46277ab2,
    name: "regional___nl-be"
  }, {
    path: "/nl-be/reset",
    component: _89466da2,
    name: "reset___nl-be"
  }, {
    path: "/nl-be/schedule",
    component: _0d16d3e7,
    name: "schedule___nl-be"
  }, {
    path: "/nl-be/search",
    component: _27203710,
    name: "search___nl-be"
  }, {
    path: "/nl-be/settings",
    component: _50c73bb6,
    name: "settings___nl-be"
  }, {
    path: "/nl-be/signup",
    component: _0f509030,
    name: "signup___nl-be"
  }, {
    path: "/nl-be/update-required",
    component: _cd17dffa,
    name: "update-required___nl-be"
  }, {
    path: "/nl-be/users",
    component: _fec5e42a,
    name: "users___nl-be"
  }, {
    path: "/nl/academy",
    component: _6fce3fcf,
    name: "academy___nl"
  }, {
    path: "/nl/accept",
    component: _64b2f850,
    name: "accept___nl"
  }, {
    path: "/nl/accept-contract",
    component: _b72eb3d2,
    name: "accept-contract___nl"
  }, {
    path: "/nl/admin",
    component: _4409af2f,
    name: "admin___nl"
  }, {
    path: "/nl/biva",
    component: _4476ba05,
    name: "biva___nl"
  }, {
    path: "/nl/bluetooth",
    component: _795d8711,
    name: "bluetooth___nl"
  }, {
    path: "/nl/config",
    component: _4858a512,
    name: "config___nl"
  }, {
    path: "/nl/customer-signup",
    component: _6f4aa872,
    name: "customer-signup___nl"
  }, {
    path: "/nl/fitness",
    component: _7b4b3bcb,
    name: "fitness___nl"
  }, {
    path: "/nl/forgot",
    component: _04942c73,
    name: "forgot___nl"
  }, {
    path: "/nl/forgot-password",
    component: _783238e5,
    name: "forgot-password___nl"
  }, {
    path: "/nl/groups",
    component: _767e5e27,
    name: "groups___nl"
  }, {
    path: "/nl/HBO-signup",
    component: _68aa9bc0,
    name: "HBO-signup___nl"
  }, {
    path: "/nl/keto",
    component: _df559b30,
    name: "keto___nl"
  }, {
    path: "/nl/login",
    component: _477c036e,
    name: "login___nl"
  }, {
    path: "/nl/regional",
    component: _46277ab2,
    name: "regional___nl"
  }, {
    path: "/nl/reset",
    component: _89466da2,
    name: "reset___nl"
  }, {
    path: "/nl/schedule",
    component: _0d16d3e7,
    name: "schedule___nl"
  }, {
    path: "/nl/search",
    component: _27203710,
    name: "search___nl"
  }, {
    path: "/nl/settings",
    component: _50c73bb6,
    name: "settings___nl"
  }, {
    path: "/nl/signup",
    component: _0f509030,
    name: "signup___nl"
  }, {
    path: "/nl/update-required",
    component: _cd17dffa,
    name: "update-required___nl"
  }, {
    path: "/nl/users",
    component: _fec5e42a,
    name: "users___nl"
  }, {
    path: "/pl/academy",
    component: _6fce3fcf,
    name: "academy___pl"
  }, {
    path: "/pl/accept",
    component: _64b2f850,
    name: "accept___pl"
  }, {
    path: "/pl/accept-contract",
    component: _b72eb3d2,
    name: "accept-contract___pl"
  }, {
    path: "/pl/admin",
    component: _4409af2f,
    name: "admin___pl"
  }, {
    path: "/pl/biva",
    component: _4476ba05,
    name: "biva___pl"
  }, {
    path: "/pl/bluetooth",
    component: _795d8711,
    name: "bluetooth___pl"
  }, {
    path: "/pl/config",
    component: _4858a512,
    name: "config___pl"
  }, {
    path: "/pl/customer-signup",
    component: _6f4aa872,
    name: "customer-signup___pl"
  }, {
    path: "/pl/fitness",
    component: _7b4b3bcb,
    name: "fitness___pl"
  }, {
    path: "/pl/forgot",
    component: _04942c73,
    name: "forgot___pl"
  }, {
    path: "/pl/forgot-password",
    component: _783238e5,
    name: "forgot-password___pl"
  }, {
    path: "/pl/groups",
    component: _767e5e27,
    name: "groups___pl"
  }, {
    path: "/pl/HBO-signup",
    component: _68aa9bc0,
    name: "HBO-signup___pl"
  }, {
    path: "/pl/keto",
    component: _df559b30,
    name: "keto___pl"
  }, {
    path: "/pl/login",
    component: _477c036e,
    name: "login___pl"
  }, {
    path: "/pl/regional",
    component: _46277ab2,
    name: "regional___pl"
  }, {
    path: "/pl/reset",
    component: _89466da2,
    name: "reset___pl"
  }, {
    path: "/pl/schedule",
    component: _0d16d3e7,
    name: "schedule___pl"
  }, {
    path: "/pl/search",
    component: _27203710,
    name: "search___pl"
  }, {
    path: "/pl/settings",
    component: _50c73bb6,
    name: "settings___pl"
  }, {
    path: "/pl/signup",
    component: _0f509030,
    name: "signup___pl"
  }, {
    path: "/pl/update-required",
    component: _cd17dffa,
    name: "update-required___pl"
  }, {
    path: "/pl/users",
    component: _fec5e42a,
    name: "users___pl"
  }, {
    path: "/regional/groups",
    component: _615262b2,
    name: "regional-groups___en"
  }, {
    path: "/settings/documents",
    component: _37f07c9c,
    name: "settings-documents___en"
  }, {
    path: "/settings/licenses",
    component: _dd43ddc4,
    name: "settings-licenses___en"
  }, {
    path: "/zh/academy",
    component: _6fce3fcf,
    name: "academy___zh"
  }, {
    path: "/zh/accept",
    component: _64b2f850,
    name: "accept___zh"
  }, {
    path: "/zh/accept-contract",
    component: _b72eb3d2,
    name: "accept-contract___zh"
  }, {
    path: "/zh/admin",
    component: _4409af2f,
    name: "admin___zh"
  }, {
    path: "/zh/biva",
    component: _4476ba05,
    name: "biva___zh"
  }, {
    path: "/zh/bluetooth",
    component: _795d8711,
    name: "bluetooth___zh"
  }, {
    path: "/zh/config",
    component: _4858a512,
    name: "config___zh"
  }, {
    path: "/zh/customer-signup",
    component: _6f4aa872,
    name: "customer-signup___zh"
  }, {
    path: "/zh/fitness",
    component: _7b4b3bcb,
    name: "fitness___zh"
  }, {
    path: "/zh/forgot",
    component: _04942c73,
    name: "forgot___zh"
  }, {
    path: "/zh/forgot-password",
    component: _783238e5,
    name: "forgot-password___zh"
  }, {
    path: "/zh/groups",
    component: _767e5e27,
    name: "groups___zh"
  }, {
    path: "/zh/HBO-signup",
    component: _68aa9bc0,
    name: "HBO-signup___zh"
  }, {
    path: "/zh/keto",
    component: _df559b30,
    name: "keto___zh"
  }, {
    path: "/zh/login",
    component: _477c036e,
    name: "login___zh"
  }, {
    path: "/zh/regional",
    component: _46277ab2,
    name: "regional___zh"
  }, {
    path: "/zh/reset",
    component: _89466da2,
    name: "reset___zh"
  }, {
    path: "/zh/schedule",
    component: _0d16d3e7,
    name: "schedule___zh"
  }, {
    path: "/zh/search",
    component: _27203710,
    name: "search___zh"
  }, {
    path: "/zh/settings",
    component: _50c73bb6,
    name: "settings___zh"
  }, {
    path: "/zh/signup",
    component: _0f509030,
    name: "signup___zh"
  }, {
    path: "/zh/update-required",
    component: _cd17dffa,
    name: "update-required___zh"
  }, {
    path: "/zh/users",
    component: _fec5e42a,
    name: "users___zh"
  }, {
    path: "/de/academy/material",
    component: _3ca26bbd,
    name: "academy-material___de"
  }, {
    path: "/de/fitness/groups",
    component: _04a54f6e,
    name: "fitness-groups___de"
  }, {
    path: "/de/groups/deleted",
    component: _7c9a6eae,
    name: "groups-deleted___de"
  }, {
    path: "/de/keto/groups",
    component: _0c99c39e,
    name: "keto-groups___de"
  }, {
    path: "/de/regional/groups",
    component: _615262b2,
    name: "regional-groups___de"
  }, {
    path: "/de/settings/documents",
    component: _37f07c9c,
    name: "settings-documents___de"
  }, {
    path: "/de/settings/licenses",
    component: _dd43ddc4,
    name: "settings-licenses___de"
  }, {
    path: "/en-gb/academy/material",
    component: _3ca26bbd,
    name: "academy-material___en-gb"
  }, {
    path: "/en-gb/fitness/groups",
    component: _04a54f6e,
    name: "fitness-groups___en-gb"
  }, {
    path: "/en-gb/groups/deleted",
    component: _7c9a6eae,
    name: "groups-deleted___en-gb"
  }, {
    path: "/en-gb/keto/groups",
    component: _0c99c39e,
    name: "keto-groups___en-gb"
  }, {
    path: "/en-gb/regional/groups",
    component: _615262b2,
    name: "regional-groups___en-gb"
  }, {
    path: "/en-gb/settings/documents",
    component: _37f07c9c,
    name: "settings-documents___en-gb"
  }, {
    path: "/en-gb/settings/licenses",
    component: _dd43ddc4,
    name: "settings-licenses___en-gb"
  }, {
    path: "/es/academy/material",
    component: _3ca26bbd,
    name: "academy-material___es"
  }, {
    path: "/es/fitness/groups",
    component: _04a54f6e,
    name: "fitness-groups___es"
  }, {
    path: "/es/groups/deleted",
    component: _7c9a6eae,
    name: "groups-deleted___es"
  }, {
    path: "/es/keto/groups",
    component: _0c99c39e,
    name: "keto-groups___es"
  }, {
    path: "/es/regional/groups",
    component: _615262b2,
    name: "regional-groups___es"
  }, {
    path: "/es/settings/documents",
    component: _37f07c9c,
    name: "settings-documents___es"
  }, {
    path: "/es/settings/licenses",
    component: _dd43ddc4,
    name: "settings-licenses___es"
  }, {
    path: "/fitness/groups/deleted",
    component: _1cca2a35,
    name: "fitness-groups-deleted___en"
  }, {
    path: "/fr/academy/material",
    component: _3ca26bbd,
    name: "academy-material___fr"
  }, {
    path: "/fr/fitness/groups",
    component: _04a54f6e,
    name: "fitness-groups___fr"
  }, {
    path: "/fr/groups/deleted",
    component: _7c9a6eae,
    name: "groups-deleted___fr"
  }, {
    path: "/fr/keto/groups",
    component: _0c99c39e,
    name: "keto-groups___fr"
  }, {
    path: "/fr/regional/groups",
    component: _615262b2,
    name: "regional-groups___fr"
  }, {
    path: "/fr/settings/documents",
    component: _37f07c9c,
    name: "settings-documents___fr"
  }, {
    path: "/fr/settings/licenses",
    component: _dd43ddc4,
    name: "settings-licenses___fr"
  }, {
    path: "/it/academy/material",
    component: _3ca26bbd,
    name: "academy-material___it"
  }, {
    path: "/it/fitness/groups",
    component: _04a54f6e,
    name: "fitness-groups___it"
  }, {
    path: "/it/groups/deleted",
    component: _7c9a6eae,
    name: "groups-deleted___it"
  }, {
    path: "/it/keto/groups",
    component: _0c99c39e,
    name: "keto-groups___it"
  }, {
    path: "/it/regional/groups",
    component: _615262b2,
    name: "regional-groups___it"
  }, {
    path: "/it/settings/documents",
    component: _37f07c9c,
    name: "settings-documents___it"
  }, {
    path: "/it/settings/licenses",
    component: _dd43ddc4,
    name: "settings-licenses___it"
  }, {
    path: "/keto/groups/deleted",
    component: _059f5438,
    name: "keto-groups-deleted___en"
  }, {
    path: "/nl-be/academy/material",
    component: _3ca26bbd,
    name: "academy-material___nl-be"
  }, {
    path: "/nl-be/fitness/groups",
    component: _04a54f6e,
    name: "fitness-groups___nl-be"
  }, {
    path: "/nl-be/groups/deleted",
    component: _7c9a6eae,
    name: "groups-deleted___nl-be"
  }, {
    path: "/nl-be/keto/groups",
    component: _0c99c39e,
    name: "keto-groups___nl-be"
  }, {
    path: "/nl-be/regional/groups",
    component: _615262b2,
    name: "regional-groups___nl-be"
  }, {
    path: "/nl-be/settings/documents",
    component: _37f07c9c,
    name: "settings-documents___nl-be"
  }, {
    path: "/nl-be/settings/licenses",
    component: _dd43ddc4,
    name: "settings-licenses___nl-be"
  }, {
    path: "/nl/academy/material",
    component: _3ca26bbd,
    name: "academy-material___nl"
  }, {
    path: "/nl/fitness/groups",
    component: _04a54f6e,
    name: "fitness-groups___nl"
  }, {
    path: "/nl/groups/deleted",
    component: _7c9a6eae,
    name: "groups-deleted___nl"
  }, {
    path: "/nl/keto/groups",
    component: _0c99c39e,
    name: "keto-groups___nl"
  }, {
    path: "/nl/regional/groups",
    component: _615262b2,
    name: "regional-groups___nl"
  }, {
    path: "/nl/settings/documents",
    component: _37f07c9c,
    name: "settings-documents___nl"
  }, {
    path: "/nl/settings/licenses",
    component: _dd43ddc4,
    name: "settings-licenses___nl"
  }, {
    path: "/pl/academy/material",
    component: _3ca26bbd,
    name: "academy-material___pl"
  }, {
    path: "/pl/fitness/groups",
    component: _04a54f6e,
    name: "fitness-groups___pl"
  }, {
    path: "/pl/groups/deleted",
    component: _7c9a6eae,
    name: "groups-deleted___pl"
  }, {
    path: "/pl/keto/groups",
    component: _0c99c39e,
    name: "keto-groups___pl"
  }, {
    path: "/pl/regional/groups",
    component: _615262b2,
    name: "regional-groups___pl"
  }, {
    path: "/pl/settings/documents",
    component: _37f07c9c,
    name: "settings-documents___pl"
  }, {
    path: "/pl/settings/licenses",
    component: _dd43ddc4,
    name: "settings-licenses___pl"
  }, {
    path: "/regional/groups/deleted",
    component: _0118bf2e,
    name: "regional-groups-deleted___en"
  }, {
    path: "/zh/academy/material",
    component: _3ca26bbd,
    name: "academy-material___zh"
  }, {
    path: "/zh/fitness/groups",
    component: _04a54f6e,
    name: "fitness-groups___zh"
  }, {
    path: "/zh/groups/deleted",
    component: _7c9a6eae,
    name: "groups-deleted___zh"
  }, {
    path: "/zh/keto/groups",
    component: _0c99c39e,
    name: "keto-groups___zh"
  }, {
    path: "/zh/regional/groups",
    component: _615262b2,
    name: "regional-groups___zh"
  }, {
    path: "/zh/settings/documents",
    component: _37f07c9c,
    name: "settings-documents___zh"
  }, {
    path: "/zh/settings/licenses",
    component: _dd43ddc4,
    name: "settings-licenses___zh"
  }, {
    path: "/de/fitness/groups/deleted",
    component: _1cca2a35,
    name: "fitness-groups-deleted___de"
  }, {
    path: "/de/keto/groups/deleted",
    component: _059f5438,
    name: "keto-groups-deleted___de"
  }, {
    path: "/de/regional/groups/deleted",
    component: _0118bf2e,
    name: "regional-groups-deleted___de"
  }, {
    path: "/en-gb/fitness/groups/deleted",
    component: _1cca2a35,
    name: "fitness-groups-deleted___en-gb"
  }, {
    path: "/en-gb/keto/groups/deleted",
    component: _059f5438,
    name: "keto-groups-deleted___en-gb"
  }, {
    path: "/en-gb/regional/groups/deleted",
    component: _0118bf2e,
    name: "regional-groups-deleted___en-gb"
  }, {
    path: "/es/fitness/groups/deleted",
    component: _1cca2a35,
    name: "fitness-groups-deleted___es"
  }, {
    path: "/es/keto/groups/deleted",
    component: _059f5438,
    name: "keto-groups-deleted___es"
  }, {
    path: "/es/regional/groups/deleted",
    component: _0118bf2e,
    name: "regional-groups-deleted___es"
  }, {
    path: "/fr/fitness/groups/deleted",
    component: _1cca2a35,
    name: "fitness-groups-deleted___fr"
  }, {
    path: "/fr/keto/groups/deleted",
    component: _059f5438,
    name: "keto-groups-deleted___fr"
  }, {
    path: "/fr/regional/groups/deleted",
    component: _0118bf2e,
    name: "regional-groups-deleted___fr"
  }, {
    path: "/it/fitness/groups/deleted",
    component: _1cca2a35,
    name: "fitness-groups-deleted___it"
  }, {
    path: "/it/keto/groups/deleted",
    component: _059f5438,
    name: "keto-groups-deleted___it"
  }, {
    path: "/it/regional/groups/deleted",
    component: _0118bf2e,
    name: "regional-groups-deleted___it"
  }, {
    path: "/nl-be/fitness/groups/deleted",
    component: _1cca2a35,
    name: "fitness-groups-deleted___nl-be"
  }, {
    path: "/nl-be/keto/groups/deleted",
    component: _059f5438,
    name: "keto-groups-deleted___nl-be"
  }, {
    path: "/nl-be/regional/groups/deleted",
    component: _0118bf2e,
    name: "regional-groups-deleted___nl-be"
  }, {
    path: "/nl/fitness/groups/deleted",
    component: _1cca2a35,
    name: "fitness-groups-deleted___nl"
  }, {
    path: "/nl/keto/groups/deleted",
    component: _059f5438,
    name: "keto-groups-deleted___nl"
  }, {
    path: "/nl/regional/groups/deleted",
    component: _0118bf2e,
    name: "regional-groups-deleted___nl"
  }, {
    path: "/pl/fitness/groups/deleted",
    component: _1cca2a35,
    name: "fitness-groups-deleted___pl"
  }, {
    path: "/pl/keto/groups/deleted",
    component: _059f5438,
    name: "keto-groups-deleted___pl"
  }, {
    path: "/pl/regional/groups/deleted",
    component: _0118bf2e,
    name: "regional-groups-deleted___pl"
  }, {
    path: "/zh/fitness/groups/deleted",
    component: _1cca2a35,
    name: "fitness-groups-deleted___zh"
  }, {
    path: "/zh/keto/groups/deleted",
    component: _059f5438,
    name: "keto-groups-deleted___zh"
  }, {
    path: "/zh/regional/groups/deleted",
    component: _0118bf2e,
    name: "regional-groups-deleted___zh"
  }, {
    path: "/de/biva/regional/:sessionId?",
    component: _2fb738b5,
    name: "biva-regional-sessionId___de"
  }, {
    path: "/de/biva/sessions/:sessionId?",
    component: _5397a277,
    name: "biva-sessions-sessionId___de"
  }, {
    path: "/de/fitness/groups/:groupId",
    component: _408b98dc,
    name: "fitness-groups-groupId___de"
  }, {
    path: "/de/keto/groups/:groupId",
    component: _7645502f,
    name: "keto-groups-groupId___de"
  }, {
    path: "/de/regional/groups/:groupId",
    component: _5fa1c479,
    name: "regional-groups-groupId___de"
  }, {
    path: "/en-gb/biva/regional/:sessionId?",
    component: _2fb738b5,
    name: "biva-regional-sessionId___en-gb"
  }, {
    path: "/en-gb/biva/sessions/:sessionId?",
    component: _5397a277,
    name: "biva-sessions-sessionId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId",
    component: _408b98dc,
    name: "fitness-groups-groupId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId",
    component: _7645502f,
    name: "keto-groups-groupId___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId",
    component: _5fa1c479,
    name: "regional-groups-groupId___en-gb"
  }, {
    path: "/es/biva/regional/:sessionId?",
    component: _2fb738b5,
    name: "biva-regional-sessionId___es"
  }, {
    path: "/es/biva/sessions/:sessionId?",
    component: _5397a277,
    name: "biva-sessions-sessionId___es"
  }, {
    path: "/es/fitness/groups/:groupId",
    component: _408b98dc,
    name: "fitness-groups-groupId___es"
  }, {
    path: "/es/keto/groups/:groupId",
    component: _7645502f,
    name: "keto-groups-groupId___es"
  }, {
    path: "/es/regional/groups/:groupId",
    component: _5fa1c479,
    name: "regional-groups-groupId___es"
  }, {
    path: "/fr/biva/regional/:sessionId?",
    component: _2fb738b5,
    name: "biva-regional-sessionId___fr"
  }, {
    path: "/fr/biva/sessions/:sessionId?",
    component: _5397a277,
    name: "biva-sessions-sessionId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId",
    component: _408b98dc,
    name: "fitness-groups-groupId___fr"
  }, {
    path: "/fr/keto/groups/:groupId",
    component: _7645502f,
    name: "keto-groups-groupId___fr"
  }, {
    path: "/fr/regional/groups/:groupId",
    component: _5fa1c479,
    name: "regional-groups-groupId___fr"
  }, {
    path: "/it/biva/regional/:sessionId?",
    component: _2fb738b5,
    name: "biva-regional-sessionId___it"
  }, {
    path: "/it/biva/sessions/:sessionId?",
    component: _5397a277,
    name: "biva-sessions-sessionId___it"
  }, {
    path: "/it/fitness/groups/:groupId",
    component: _408b98dc,
    name: "fitness-groups-groupId___it"
  }, {
    path: "/it/keto/groups/:groupId",
    component: _7645502f,
    name: "keto-groups-groupId___it"
  }, {
    path: "/it/regional/groups/:groupId",
    component: _5fa1c479,
    name: "regional-groups-groupId___it"
  }, {
    path: "/nl-be/biva/regional/:sessionId?",
    component: _2fb738b5,
    name: "biva-regional-sessionId___nl-be"
  }, {
    path: "/nl-be/biva/sessions/:sessionId?",
    component: _5397a277,
    name: "biva-sessions-sessionId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId",
    component: _408b98dc,
    name: "fitness-groups-groupId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId",
    component: _7645502f,
    name: "keto-groups-groupId___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId",
    component: _5fa1c479,
    name: "regional-groups-groupId___nl-be"
  }, {
    path: "/nl/biva/regional/:sessionId?",
    component: _2fb738b5,
    name: "biva-regional-sessionId___nl"
  }, {
    path: "/nl/biva/sessions/:sessionId?",
    component: _5397a277,
    name: "biva-sessions-sessionId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId",
    component: _408b98dc,
    name: "fitness-groups-groupId___nl"
  }, {
    path: "/nl/keto/groups/:groupId",
    component: _7645502f,
    name: "keto-groups-groupId___nl"
  }, {
    path: "/nl/regional/groups/:groupId",
    component: _5fa1c479,
    name: "regional-groups-groupId___nl"
  }, {
    path: "/pl/biva/regional/:sessionId?",
    component: _2fb738b5,
    name: "biva-regional-sessionId___pl"
  }, {
    path: "/pl/biva/sessions/:sessionId?",
    component: _5397a277,
    name: "biva-sessions-sessionId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId",
    component: _408b98dc,
    name: "fitness-groups-groupId___pl"
  }, {
    path: "/pl/keto/groups/:groupId",
    component: _7645502f,
    name: "keto-groups-groupId___pl"
  }, {
    path: "/pl/regional/groups/:groupId",
    component: _5fa1c479,
    name: "regional-groups-groupId___pl"
  }, {
    path: "/zh/biva/regional/:sessionId?",
    component: _2fb738b5,
    name: "biva-regional-sessionId___zh"
  }, {
    path: "/zh/biva/sessions/:sessionId?",
    component: _5397a277,
    name: "biva-sessions-sessionId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId",
    component: _408b98dc,
    name: "fitness-groups-groupId___zh"
  }, {
    path: "/zh/keto/groups/:groupId",
    component: _7645502f,
    name: "keto-groups-groupId___zh"
  }, {
    path: "/zh/regional/groups/:groupId",
    component: _5fa1c479,
    name: "regional-groups-groupId___zh"
  }, {
    path: "/de/fitness/groups/:groupId/deleted",
    component: _f392d54e,
    name: "fitness-groups-groupId-deleted___de"
  }, {
    path: "/de/keto/groups/:groupId/deleted",
    component: _b3260294,
    name: "keto-groups-groupId-deleted___de"
  }, {
    path: "/de/regional/groups/:groupId/deleted",
    component: _ab04ef00,
    name: "regional-groups-groupId-deleted___de"
  }, {
    path: "/en-gb/fitness/groups/:groupId/deleted",
    component: _f392d54e,
    name: "fitness-groups-groupId-deleted___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/deleted",
    component: _b3260294,
    name: "keto-groups-groupId-deleted___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/deleted",
    component: _ab04ef00,
    name: "regional-groups-groupId-deleted___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/deleted",
    component: _f392d54e,
    name: "fitness-groups-groupId-deleted___es"
  }, {
    path: "/es/keto/groups/:groupId/deleted",
    component: _b3260294,
    name: "keto-groups-groupId-deleted___es"
  }, {
    path: "/es/regional/groups/:groupId/deleted",
    component: _ab04ef00,
    name: "regional-groups-groupId-deleted___es"
  }, {
    path: "/fr/fitness/groups/:groupId/deleted",
    component: _f392d54e,
    name: "fitness-groups-groupId-deleted___fr"
  }, {
    path: "/fr/keto/groups/:groupId/deleted",
    component: _b3260294,
    name: "keto-groups-groupId-deleted___fr"
  }, {
    path: "/fr/regional/groups/:groupId/deleted",
    component: _ab04ef00,
    name: "regional-groups-groupId-deleted___fr"
  }, {
    path: "/it/fitness/groups/:groupId/deleted",
    component: _f392d54e,
    name: "fitness-groups-groupId-deleted___it"
  }, {
    path: "/it/keto/groups/:groupId/deleted",
    component: _b3260294,
    name: "keto-groups-groupId-deleted___it"
  }, {
    path: "/it/regional/groups/:groupId/deleted",
    component: _ab04ef00,
    name: "regional-groups-groupId-deleted___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/deleted",
    component: _f392d54e,
    name: "fitness-groups-groupId-deleted___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/deleted",
    component: _b3260294,
    name: "keto-groups-groupId-deleted___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/deleted",
    component: _ab04ef00,
    name: "regional-groups-groupId-deleted___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/deleted",
    component: _f392d54e,
    name: "fitness-groups-groupId-deleted___nl"
  }, {
    path: "/nl/keto/groups/:groupId/deleted",
    component: _b3260294,
    name: "keto-groups-groupId-deleted___nl"
  }, {
    path: "/nl/regional/groups/:groupId/deleted",
    component: _ab04ef00,
    name: "regional-groups-groupId-deleted___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/deleted",
    component: _f392d54e,
    name: "fitness-groups-groupId-deleted___pl"
  }, {
    path: "/pl/keto/groups/:groupId/deleted",
    component: _b3260294,
    name: "keto-groups-groupId-deleted___pl"
  }, {
    path: "/pl/regional/groups/:groupId/deleted",
    component: _ab04ef00,
    name: "regional-groups-groupId-deleted___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/deleted",
    component: _f392d54e,
    name: "fitness-groups-groupId-deleted___zh"
  }, {
    path: "/zh/keto/groups/:groupId/deleted",
    component: _b3260294,
    name: "keto-groups-groupId-deleted___zh"
  }, {
    path: "/zh/regional/groups/:groupId/deleted",
    component: _ab04ef00,
    name: "regional-groups-groupId-deleted___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?",
    component: _339b0c36,
    name: "fitness-groups-groupId-patients-patientId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?",
    component: _3de62602,
    name: "keto-groups-groupId-patients-patientId___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?",
    component: _5690dd68,
    name: "regional-groups-groupId-patients-patientId___de"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?",
    component: _339b0c36,
    name: "fitness-groups-groupId-patients-patientId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?",
    component: _3de62602,
    name: "keto-groups-groupId-patients-patientId___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?",
    component: _5690dd68,
    name: "regional-groups-groupId-patients-patientId___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?",
    component: _339b0c36,
    name: "fitness-groups-groupId-patients-patientId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?",
    component: _3de62602,
    name: "keto-groups-groupId-patients-patientId___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?",
    component: _5690dd68,
    name: "regional-groups-groupId-patients-patientId___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?",
    component: _339b0c36,
    name: "fitness-groups-groupId-patients-patientId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?",
    component: _3de62602,
    name: "keto-groups-groupId-patients-patientId___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?",
    component: _5690dd68,
    name: "regional-groups-groupId-patients-patientId___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?",
    component: _339b0c36,
    name: "fitness-groups-groupId-patients-patientId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?",
    component: _3de62602,
    name: "keto-groups-groupId-patients-patientId___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?",
    component: _5690dd68,
    name: "regional-groups-groupId-patients-patientId___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?",
    component: _339b0c36,
    name: "fitness-groups-groupId-patients-patientId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?",
    component: _3de62602,
    name: "keto-groups-groupId-patients-patientId___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?",
    component: _5690dd68,
    name: "regional-groups-groupId-patients-patientId___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?",
    component: _339b0c36,
    name: "fitness-groups-groupId-patients-patientId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?",
    component: _3de62602,
    name: "keto-groups-groupId-patients-patientId___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?",
    component: _5690dd68,
    name: "regional-groups-groupId-patients-patientId___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?",
    component: _339b0c36,
    name: "fitness-groups-groupId-patients-patientId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?",
    component: _3de62602,
    name: "keto-groups-groupId-patients-patientId___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?",
    component: _5690dd68,
    name: "regional-groups-groupId-patients-patientId___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?",
    component: _339b0c36,
    name: "fitness-groups-groupId-patients-patientId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?",
    component: _3de62602,
    name: "keto-groups-groupId-patients-patientId___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?",
    component: _5690dd68,
    name: "regional-groups-groupId-patients-patientId___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _64e7c2bc,
    name: "fitness-groups-groupId-patients-patientId-somatotype___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _00c07ca5,
    name: "keto-groups-groupId-patients-patientId-somatotype___de"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _64e7c2bc,
    name: "fitness-groups-groupId-patients-patientId-somatotype___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _00c07ca5,
    name: "keto-groups-groupId-patients-patientId-somatotype___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _64e7c2bc,
    name: "fitness-groups-groupId-patients-patientId-somatotype___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _00c07ca5,
    name: "keto-groups-groupId-patients-patientId-somatotype___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _64e7c2bc,
    name: "fitness-groups-groupId-patients-patientId-somatotype___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _00c07ca5,
    name: "keto-groups-groupId-patients-patientId-somatotype___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _64e7c2bc,
    name: "fitness-groups-groupId-patients-patientId-somatotype___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _00c07ca5,
    name: "keto-groups-groupId-patients-patientId-somatotype___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _64e7c2bc,
    name: "fitness-groups-groupId-patients-patientId-somatotype___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _00c07ca5,
    name: "keto-groups-groupId-patients-patientId-somatotype___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _64e7c2bc,
    name: "fitness-groups-groupId-patients-patientId-somatotype___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _00c07ca5,
    name: "keto-groups-groupId-patients-patientId-somatotype___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _64e7c2bc,
    name: "fitness-groups-groupId-patients-patientId-somatotype___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _00c07ca5,
    name: "keto-groups-groupId-patients-patientId-somatotype___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _64e7c2bc,
    name: "fitness-groups-groupId-patients-patientId-somatotype___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _00c07ca5,
    name: "keto-groups-groupId-patients-patientId-somatotype___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _72b8d551,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _2dc1146e,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _4c80d490,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___de"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _72b8d551,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _2dc1146e,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _4c80d490,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _72b8d551,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _2dc1146e,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _4c80d490,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _72b8d551,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _2dc1146e,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _4c80d490,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _72b8d551,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _2dc1146e,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _4c80d490,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _72b8d551,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _2dc1146e,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _4c80d490,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _72b8d551,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _2dc1146e,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _4c80d490,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _72b8d551,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _2dc1146e,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _4c80d490,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _72b8d551,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _2dc1146e,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _4c80d490,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _234d372a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _cf9c3a12,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _0904d06a,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _b1b0d498,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _98ab1faa,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _63f87158,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _15fff458,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _1301f42e,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _f55b570c,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _45cba80e,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _ba4d69de,
    name: "keto-groups-groupId-patients-patientId-exams-examId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _4b16e624,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _6fe6609e,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _21ede39e,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _26c02cdb,
    name: "regional-groups-groupId-patients-patientId-exams-examId___de"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _234d372a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _cf9c3a12,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _0904d06a,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _b1b0d498,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _98ab1faa,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _63f87158,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _15fff458,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _1301f42e,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _f55b570c,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _45cba80e,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _ba4d69de,
    name: "keto-groups-groupId-patients-patientId-exams-examId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _4b16e624,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _6fe6609e,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _21ede39e,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _26c02cdb,
    name: "regional-groups-groupId-patients-patientId-exams-examId___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _234d372a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _cf9c3a12,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _0904d06a,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _b1b0d498,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _98ab1faa,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _63f87158,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _15fff458,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _1301f42e,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _f55b570c,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _45cba80e,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _ba4d69de,
    name: "keto-groups-groupId-patients-patientId-exams-examId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _4b16e624,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _6fe6609e,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _21ede39e,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _26c02cdb,
    name: "regional-groups-groupId-patients-patientId-exams-examId___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _234d372a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _cf9c3a12,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _0904d06a,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _b1b0d498,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _98ab1faa,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _63f87158,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _15fff458,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _1301f42e,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _f55b570c,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _45cba80e,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _ba4d69de,
    name: "keto-groups-groupId-patients-patientId-exams-examId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _4b16e624,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _6fe6609e,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _21ede39e,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _26c02cdb,
    name: "regional-groups-groupId-patients-patientId-exams-examId___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _234d372a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _cf9c3a12,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _0904d06a,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _b1b0d498,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _98ab1faa,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _63f87158,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _15fff458,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _1301f42e,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _f55b570c,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _45cba80e,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _ba4d69de,
    name: "keto-groups-groupId-patients-patientId-exams-examId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _4b16e624,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _6fe6609e,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _21ede39e,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _26c02cdb,
    name: "regional-groups-groupId-patients-patientId-exams-examId___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _234d372a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _cf9c3a12,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _0904d06a,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _b1b0d498,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _98ab1faa,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _63f87158,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _15fff458,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _1301f42e,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _f55b570c,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _45cba80e,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _ba4d69de,
    name: "keto-groups-groupId-patients-patientId-exams-examId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _4b16e624,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _6fe6609e,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _21ede39e,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _26c02cdb,
    name: "regional-groups-groupId-patients-patientId-exams-examId___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _234d372a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _cf9c3a12,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _0904d06a,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _b1b0d498,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _98ab1faa,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _63f87158,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _15fff458,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _1301f42e,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _f55b570c,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _45cba80e,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _ba4d69de,
    name: "keto-groups-groupId-patients-patientId-exams-examId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _4b16e624,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _6fe6609e,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _21ede39e,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _26c02cdb,
    name: "regional-groups-groupId-patients-patientId-exams-examId___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _234d372a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _cf9c3a12,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _0904d06a,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _b1b0d498,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _98ab1faa,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _63f87158,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _15fff458,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _1301f42e,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _f55b570c,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _45cba80e,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _ba4d69de,
    name: "keto-groups-groupId-patients-patientId-exams-examId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _4b16e624,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _6fe6609e,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _21ede39e,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _26c02cdb,
    name: "regional-groups-groupId-patients-patientId-exams-examId___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _234d372a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _cf9c3a12,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _0904d06a,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _b1b0d498,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _98ab1faa,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _63f87158,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _15fff458,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _1301f42e,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _f55b570c,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _45cba80e,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _ba4d69de,
    name: "keto-groups-groupId-patients-patientId-exams-examId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _4b16e624,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _6fe6609e,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _21ede39e,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _26c02cdb,
    name: "regional-groups-groupId-patients-patientId-exams-examId___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _3dc3f0d3,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _0535f629,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _6e92f02b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _c5fb48b8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _533d3e64,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _73a6071b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _22ac0596,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _37637002,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _6c603f48,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _4a1bb776,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _919fab00,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _751d2102,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _41f9ee11,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _29ee8b98,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _8194e018,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _2a4eb22d,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1ac04936,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _41924138,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _a1efe2ec,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _5c69e79a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _046f9234,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _fb7ff40c,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _925710f0,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _4b02c858,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _3878d554,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _49606f74,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _210c6bc8,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _ebba65b2,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _e561c0aa,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _cb1fb844,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _2b489adc,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _2e9c5842,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _3a3948f9,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _0400ce08,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _0ab5928a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _440b717a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _8795b776,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _68c40ad4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _4e971b5b,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _293bdac6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _5bf93af7,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _466fa46c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _e1545930,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _4d80307e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _0ed5ae14,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _8dc1f2e8,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _784d2d29,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _1e286b17,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _9e450036,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _773493d7,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _55bbd57a,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _3ab55f8b,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _ef1303ec,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _52938e32,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___de"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _3dc3f0d3,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _0535f629,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _6e92f02b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _c5fb48b8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _533d3e64,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _73a6071b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _22ac0596,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _37637002,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _6c603f48,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _4a1bb776,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _919fab00,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _751d2102,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _41f9ee11,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _29ee8b98,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _8194e018,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _2a4eb22d,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1ac04936,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _41924138,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _a1efe2ec,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _5c69e79a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _046f9234,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _fb7ff40c,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _925710f0,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _4b02c858,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _3878d554,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _49606f74,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _210c6bc8,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _ebba65b2,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _e561c0aa,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _cb1fb844,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _2b489adc,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _2e9c5842,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _3a3948f9,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _0400ce08,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _0ab5928a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _440b717a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _8795b776,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _68c40ad4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _4e971b5b,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _293bdac6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _5bf93af7,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _466fa46c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _e1545930,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _4d80307e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _0ed5ae14,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _8dc1f2e8,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _784d2d29,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _1e286b17,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _9e450036,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _773493d7,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _55bbd57a,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _3ab55f8b,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _ef1303ec,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _52938e32,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _3dc3f0d3,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _0535f629,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _6e92f02b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _c5fb48b8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _533d3e64,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _73a6071b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _22ac0596,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _37637002,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _6c603f48,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _4a1bb776,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _919fab00,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _751d2102,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _41f9ee11,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _29ee8b98,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _8194e018,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _2a4eb22d,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1ac04936,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _41924138,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _a1efe2ec,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _5c69e79a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _046f9234,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _fb7ff40c,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _925710f0,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _4b02c858,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _3878d554,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _49606f74,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _210c6bc8,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _ebba65b2,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _e561c0aa,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _cb1fb844,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _2b489adc,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _2e9c5842,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _3a3948f9,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _0400ce08,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _0ab5928a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _440b717a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _8795b776,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _68c40ad4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _4e971b5b,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _293bdac6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _5bf93af7,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _466fa46c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _e1545930,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _4d80307e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _0ed5ae14,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _8dc1f2e8,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _784d2d29,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _1e286b17,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _9e450036,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _773493d7,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _55bbd57a,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _3ab55f8b,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _ef1303ec,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _52938e32,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _3dc3f0d3,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _0535f629,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _6e92f02b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _c5fb48b8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _533d3e64,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _73a6071b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _22ac0596,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _37637002,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _6c603f48,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _4a1bb776,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _919fab00,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _751d2102,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _41f9ee11,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _29ee8b98,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _8194e018,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _2a4eb22d,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1ac04936,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _41924138,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _a1efe2ec,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _5c69e79a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _046f9234,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _fb7ff40c,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _925710f0,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _4b02c858,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _3878d554,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _49606f74,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _210c6bc8,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _ebba65b2,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _e561c0aa,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _cb1fb844,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _2b489adc,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _2e9c5842,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _3a3948f9,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _0400ce08,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _0ab5928a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _440b717a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _8795b776,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _68c40ad4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _4e971b5b,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _293bdac6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _5bf93af7,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _466fa46c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _e1545930,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _4d80307e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _0ed5ae14,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _8dc1f2e8,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _784d2d29,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _1e286b17,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _9e450036,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _773493d7,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _55bbd57a,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _3ab55f8b,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _ef1303ec,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _52938e32,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _3dc3f0d3,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _0535f629,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _6e92f02b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _c5fb48b8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _533d3e64,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _73a6071b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _22ac0596,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _37637002,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _6c603f48,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _4a1bb776,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _919fab00,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _751d2102,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _41f9ee11,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _29ee8b98,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _8194e018,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _2a4eb22d,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1ac04936,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _41924138,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _a1efe2ec,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _5c69e79a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _046f9234,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _fb7ff40c,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _925710f0,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _4b02c858,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _3878d554,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _49606f74,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _210c6bc8,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _ebba65b2,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _e561c0aa,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _cb1fb844,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _2b489adc,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _2e9c5842,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _3a3948f9,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _0400ce08,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _0ab5928a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _440b717a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _8795b776,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _68c40ad4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _4e971b5b,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _293bdac6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _5bf93af7,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _466fa46c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _e1545930,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _4d80307e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _0ed5ae14,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _8dc1f2e8,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _784d2d29,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _1e286b17,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _9e450036,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _773493d7,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _55bbd57a,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _3ab55f8b,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _ef1303ec,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _52938e32,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _3dc3f0d3,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _0535f629,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _6e92f02b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _c5fb48b8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _533d3e64,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _73a6071b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _22ac0596,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _37637002,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _6c603f48,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _4a1bb776,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _919fab00,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _751d2102,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _41f9ee11,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _29ee8b98,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _8194e018,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _2a4eb22d,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1ac04936,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _41924138,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _a1efe2ec,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _5c69e79a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _046f9234,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _fb7ff40c,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _925710f0,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _4b02c858,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _3878d554,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _49606f74,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _210c6bc8,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _ebba65b2,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _e561c0aa,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _cb1fb844,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _2b489adc,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _2e9c5842,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _3a3948f9,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _0400ce08,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _0ab5928a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _440b717a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _8795b776,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _68c40ad4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _4e971b5b,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _293bdac6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _5bf93af7,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _466fa46c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _e1545930,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _4d80307e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _0ed5ae14,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _8dc1f2e8,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _784d2d29,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _1e286b17,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _9e450036,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _773493d7,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _55bbd57a,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _3ab55f8b,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _ef1303ec,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _52938e32,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _3dc3f0d3,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _0535f629,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _6e92f02b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _c5fb48b8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _533d3e64,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _73a6071b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _22ac0596,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _37637002,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _6c603f48,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _4a1bb776,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _919fab00,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _751d2102,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _41f9ee11,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _29ee8b98,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _8194e018,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _2a4eb22d,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1ac04936,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _41924138,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _a1efe2ec,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _5c69e79a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _046f9234,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _fb7ff40c,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _925710f0,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _4b02c858,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _3878d554,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _49606f74,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _210c6bc8,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _ebba65b2,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _e561c0aa,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _cb1fb844,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _2b489adc,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _2e9c5842,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _3a3948f9,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _0400ce08,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _0ab5928a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _440b717a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _8795b776,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _68c40ad4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _4e971b5b,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _293bdac6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _5bf93af7,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _466fa46c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _e1545930,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _4d80307e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _0ed5ae14,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _8dc1f2e8,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _784d2d29,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _1e286b17,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _9e450036,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _773493d7,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _55bbd57a,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _3ab55f8b,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _ef1303ec,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _52938e32,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _3dc3f0d3,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _0535f629,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _6e92f02b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _c5fb48b8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _533d3e64,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _73a6071b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _22ac0596,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _37637002,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _6c603f48,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _4a1bb776,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _919fab00,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _751d2102,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _41f9ee11,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _29ee8b98,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _8194e018,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _2a4eb22d,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1ac04936,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _41924138,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _a1efe2ec,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _5c69e79a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _046f9234,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _fb7ff40c,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _925710f0,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _4b02c858,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _3878d554,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _49606f74,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _210c6bc8,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _ebba65b2,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _e561c0aa,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _cb1fb844,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _2b489adc,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _2e9c5842,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _3a3948f9,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _0400ce08,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _0ab5928a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _440b717a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _8795b776,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _68c40ad4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _4e971b5b,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _293bdac6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _5bf93af7,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _466fa46c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _e1545930,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _4d80307e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _0ed5ae14,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _8dc1f2e8,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _784d2d29,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _1e286b17,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _9e450036,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _773493d7,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _55bbd57a,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _3ab55f8b,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _ef1303ec,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _52938e32,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _3dc3f0d3,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _0535f629,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _6e92f02b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _c5fb48b8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _533d3e64,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _73a6071b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _22ac0596,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _37637002,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _6c603f48,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _4a1bb776,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _919fab00,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _751d2102,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _41f9ee11,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _29ee8b98,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _8194e018,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _2a4eb22d,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1ac04936,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _41924138,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _a1efe2ec,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _5c69e79a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _046f9234,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _fb7ff40c,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _925710f0,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _4b02c858,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _3878d554,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _49606f74,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _210c6bc8,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _ebba65b2,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _e561c0aa,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _cb1fb844,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _2b489adc,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _2e9c5842,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _3a3948f9,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _0400ce08,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _0ab5928a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _440b717a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _8795b776,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _68c40ad4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _4e971b5b,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _293bdac6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _5bf93af7,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _466fa46c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _e1545930,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _4d80307e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _0ed5ae14,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _8dc1f2e8,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _784d2d29,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _1e286b17,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _9e450036,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _773493d7,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _55bbd57a,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _3ab55f8b,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _ef1303ec,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _52938e32,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _44cf2c6a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _6938df33,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _e1c586a6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _5b8f4410,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _50ed5cda,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___de"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _44cf2c6a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _6938df33,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _e1c586a6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _5b8f4410,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _50ed5cda,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _44cf2c6a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _6938df33,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _e1c586a6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _5b8f4410,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _50ed5cda,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _44cf2c6a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _6938df33,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _e1c586a6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _5b8f4410,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _50ed5cda,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _44cf2c6a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _6938df33,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _e1c586a6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _5b8f4410,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _50ed5cda,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _44cf2c6a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _6938df33,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _e1c586a6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _5b8f4410,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _50ed5cda,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _44cf2c6a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _6938df33,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _e1c586a6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _5b8f4410,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _50ed5cda,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _44cf2c6a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _6938df33,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _e1c586a6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _5b8f4410,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _50ed5cda,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _44cf2c6a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _6938df33,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _e1c586a6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _5b8f4410,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _50ed5cda,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___zh"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _7dcb793c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___de"
  }, {
    path: "/de/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _32cfc0da,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _7d3f7159,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___de"
  }, {
    path: "/de/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _3f0e5656,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___de"
  }, {
    path: "/de/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _15195268,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___de"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _7dcb793c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _32cfc0da,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _7d3f7159,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _3f0e5656,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _15195268,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _7dcb793c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___es"
  }, {
    path: "/es/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _32cfc0da,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _7d3f7159,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___es"
  }, {
    path: "/es/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _3f0e5656,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___es"
  }, {
    path: "/es/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _15195268,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___es"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _7dcb793c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___fr"
  }, {
    path: "/fr/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _32cfc0da,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _7d3f7159,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___fr"
  }, {
    path: "/fr/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _3f0e5656,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___fr"
  }, {
    path: "/fr/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _15195268,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___fr"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _7dcb793c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___it"
  }, {
    path: "/it/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _32cfc0da,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _7d3f7159,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___it"
  }, {
    path: "/it/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _3f0e5656,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___it"
  }, {
    path: "/it/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _15195268,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___it"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _7dcb793c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _32cfc0da,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _7d3f7159,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _3f0e5656,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _15195268,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _7dcb793c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___nl"
  }, {
    path: "/nl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _32cfc0da,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _7d3f7159,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___nl"
  }, {
    path: "/nl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _3f0e5656,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl"
  }, {
    path: "/nl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _15195268,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___nl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _7dcb793c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___pl"
  }, {
    path: "/pl/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _32cfc0da,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _7d3f7159,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___pl"
  }, {
    path: "/pl/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _3f0e5656,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___pl"
  }, {
    path: "/pl/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _15195268,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___pl"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _7dcb793c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___zh"
  }, {
    path: "/zh/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _32cfc0da,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _7d3f7159,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___zh"
  }, {
    path: "/zh/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _3f0e5656,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___zh"
  }, {
    path: "/zh/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _15195268,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___zh"
  }, {
    path: "/biva/regional/:sessionId?",
    component: _2fb738b5,
    name: "biva-regional-sessionId___en"
  }, {
    path: "/biva/sessions/:sessionId?",
    component: _5397a277,
    name: "biva-sessions-sessionId___en"
  }, {
    path: "/de/groups/:groupId",
    component: _0178b60e,
    name: "groups-groupId___de"
  }, {
    path: "/en-gb/groups/:groupId",
    component: _0178b60e,
    name: "groups-groupId___en-gb"
  }, {
    path: "/es/groups/:groupId",
    component: _0178b60e,
    name: "groups-groupId___es"
  }, {
    path: "/fitness/groups/:groupId",
    component: _408b98dc,
    name: "fitness-groups-groupId___en"
  }, {
    path: "/fr/groups/:groupId",
    component: _0178b60e,
    name: "groups-groupId___fr"
  }, {
    path: "/it/groups/:groupId",
    component: _0178b60e,
    name: "groups-groupId___it"
  }, {
    path: "/keto/groups/:groupId",
    component: _7645502f,
    name: "keto-groups-groupId___en"
  }, {
    path: "/nl-be/groups/:groupId",
    component: _0178b60e,
    name: "groups-groupId___nl-be"
  }, {
    path: "/nl/groups/:groupId",
    component: _0178b60e,
    name: "groups-groupId___nl"
  }, {
    path: "/pl/groups/:groupId",
    component: _0178b60e,
    name: "groups-groupId___pl"
  }, {
    path: "/regional/groups/:groupId",
    component: _5fa1c479,
    name: "regional-groups-groupId___en"
  }, {
    path: "/zh/groups/:groupId",
    component: _0178b60e,
    name: "groups-groupId___zh"
  }, {
    path: "/de/groups/:groupId/bia",
    component: _59c0fce1,
    name: "groups-groupId-bia___de"
  }, {
    path: "/de/groups/:groupId/deleted",
    component: _2dad6e00,
    name: "groups-groupId-deleted___de"
  }, {
    path: "/en-gb/groups/:groupId/bia",
    component: _59c0fce1,
    name: "groups-groupId-bia___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/deleted",
    component: _2dad6e00,
    name: "groups-groupId-deleted___en-gb"
  }, {
    path: "/es/groups/:groupId/bia",
    component: _59c0fce1,
    name: "groups-groupId-bia___es"
  }, {
    path: "/es/groups/:groupId/deleted",
    component: _2dad6e00,
    name: "groups-groupId-deleted___es"
  }, {
    path: "/fitness/groups/:groupId/deleted",
    component: _f392d54e,
    name: "fitness-groups-groupId-deleted___en"
  }, {
    path: "/fr/groups/:groupId/bia",
    component: _59c0fce1,
    name: "groups-groupId-bia___fr"
  }, {
    path: "/fr/groups/:groupId/deleted",
    component: _2dad6e00,
    name: "groups-groupId-deleted___fr"
  }, {
    path: "/it/groups/:groupId/bia",
    component: _59c0fce1,
    name: "groups-groupId-bia___it"
  }, {
    path: "/it/groups/:groupId/deleted",
    component: _2dad6e00,
    name: "groups-groupId-deleted___it"
  }, {
    path: "/keto/groups/:groupId/deleted",
    component: _b3260294,
    name: "keto-groups-groupId-deleted___en"
  }, {
    path: "/nl-be/groups/:groupId/bia",
    component: _59c0fce1,
    name: "groups-groupId-bia___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/deleted",
    component: _2dad6e00,
    name: "groups-groupId-deleted___nl-be"
  }, {
    path: "/nl/groups/:groupId/bia",
    component: _59c0fce1,
    name: "groups-groupId-bia___nl"
  }, {
    path: "/nl/groups/:groupId/deleted",
    component: _2dad6e00,
    name: "groups-groupId-deleted___nl"
  }, {
    path: "/pl/groups/:groupId/bia",
    component: _59c0fce1,
    name: "groups-groupId-bia___pl"
  }, {
    path: "/pl/groups/:groupId/deleted",
    component: _2dad6e00,
    name: "groups-groupId-deleted___pl"
  }, {
    path: "/regional/groups/:groupId/deleted",
    component: _ab04ef00,
    name: "regional-groups-groupId-deleted___en"
  }, {
    path: "/zh/groups/:groupId/bia",
    component: _59c0fce1,
    name: "groups-groupId-bia___zh"
  }, {
    path: "/zh/groups/:groupId/deleted",
    component: _2dad6e00,
    name: "groups-groupId-deleted___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?",
    component: _7d2431cc,
    name: "groups-groupId-patients-patientId___de"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?",
    component: _7d2431cc,
    name: "groups-groupId-patients-patientId___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?",
    component: _7d2431cc,
    name: "groups-groupId-patients-patientId___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?",
    component: _339b0c36,
    name: "fitness-groups-groupId-patients-patientId___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?",
    component: _7d2431cc,
    name: "groups-groupId-patients-patientId___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?",
    component: _7d2431cc,
    name: "groups-groupId-patients-patientId___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?",
    component: _3de62602,
    name: "keto-groups-groupId-patients-patientId___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?",
    component: _7d2431cc,
    name: "groups-groupId-patients-patientId___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?",
    component: _7d2431cc,
    name: "groups-groupId-patients-patientId___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?",
    component: _7d2431cc,
    name: "groups-groupId-patients-patientId___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?",
    component: _5690dd68,
    name: "regional-groups-groupId-patients-patientId___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?",
    component: _7d2431cc,
    name: "groups-groupId-patients-patientId___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/activities",
    component: _68ea83b4,
    name: "groups-groupId-patients-patientId-activities___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan",
    component: _46a14a58,
    name: "groups-groupId-patients-patientId-bodyscan___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/grid",
    component: _c6c6b188,
    name: "groups-groupId-patients-patientId-grid___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/schedule",
    component: _0b7b3f0d,
    name: "groups-groupId-patients-patientId-schedule___de"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/activities",
    component: _68ea83b4,
    name: "groups-groupId-patients-patientId-activities___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan",
    component: _46a14a58,
    name: "groups-groupId-patients-patientId-bodyscan___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/grid",
    component: _c6c6b188,
    name: "groups-groupId-patients-patientId-grid___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/schedule",
    component: _0b7b3f0d,
    name: "groups-groupId-patients-patientId-schedule___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/activities",
    component: _68ea83b4,
    name: "groups-groupId-patients-patientId-activities___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan",
    component: _46a14a58,
    name: "groups-groupId-patients-patientId-bodyscan___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/grid",
    component: _c6c6b188,
    name: "groups-groupId-patients-patientId-grid___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/schedule",
    component: _0b7b3f0d,
    name: "groups-groupId-patients-patientId-schedule___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/somatotype",
    component: _64e7c2bc,
    name: "fitness-groups-groupId-patients-patientId-somatotype___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/activities",
    component: _68ea83b4,
    name: "groups-groupId-patients-patientId-activities___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan",
    component: _46a14a58,
    name: "groups-groupId-patients-patientId-bodyscan___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/grid",
    component: _c6c6b188,
    name: "groups-groupId-patients-patientId-grid___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/schedule",
    component: _0b7b3f0d,
    name: "groups-groupId-patients-patientId-schedule___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/activities",
    component: _68ea83b4,
    name: "groups-groupId-patients-patientId-activities___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan",
    component: _46a14a58,
    name: "groups-groupId-patients-patientId-bodyscan___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/grid",
    component: _c6c6b188,
    name: "groups-groupId-patients-patientId-grid___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/schedule",
    component: _0b7b3f0d,
    name: "groups-groupId-patients-patientId-schedule___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/somatotype",
    component: _00c07ca5,
    name: "keto-groups-groupId-patients-patientId-somatotype___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/activities",
    component: _68ea83b4,
    name: "groups-groupId-patients-patientId-activities___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan",
    component: _46a14a58,
    name: "groups-groupId-patients-patientId-bodyscan___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/grid",
    component: _c6c6b188,
    name: "groups-groupId-patients-patientId-grid___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/schedule",
    component: _0b7b3f0d,
    name: "groups-groupId-patients-patientId-schedule___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/activities",
    component: _68ea83b4,
    name: "groups-groupId-patients-patientId-activities___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan",
    component: _46a14a58,
    name: "groups-groupId-patients-patientId-bodyscan___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/grid",
    component: _c6c6b188,
    name: "groups-groupId-patients-patientId-grid___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/schedule",
    component: _0b7b3f0d,
    name: "groups-groupId-patients-patientId-schedule___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/activities",
    component: _68ea83b4,
    name: "groups-groupId-patients-patientId-activities___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan",
    component: _46a14a58,
    name: "groups-groupId-patients-patientId-bodyscan___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/grid",
    component: _c6c6b188,
    name: "groups-groupId-patients-patientId-grid___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/schedule",
    component: _0b7b3f0d,
    name: "groups-groupId-patients-patientId-schedule___pl"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/activities",
    component: _68ea83b4,
    name: "groups-groupId-patients-patientId-activities___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan",
    component: _46a14a58,
    name: "groups-groupId-patients-patientId-bodyscan___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/grid",
    component: _c6c6b188,
    name: "groups-groupId-patients-patientId-grid___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/schedule",
    component: _0b7b3f0d,
    name: "groups-groupId-patients-patientId-schedule___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _fbd765a0,
    name: "groups-groupId-patients-patientId-bodyscan-grid___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3dc3b638,
    name: "groups-groupId-patients-patientId-exams-deleted___de"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _fbd765a0,
    name: "groups-groupId-patients-patientId-bodyscan-grid___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3dc3b638,
    name: "groups-groupId-patients-patientId-exams-deleted___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _fbd765a0,
    name: "groups-groupId-patients-patientId-bodyscan-grid___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3dc3b638,
    name: "groups-groupId-patients-patientId-exams-deleted___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _72b8d551,
    name: "fitness-groups-groupId-patients-patientId-exams-deleted___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _fbd765a0,
    name: "groups-groupId-patients-patientId-bodyscan-grid___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3dc3b638,
    name: "groups-groupId-patients-patientId-exams-deleted___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _fbd765a0,
    name: "groups-groupId-patients-patientId-bodyscan-grid___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3dc3b638,
    name: "groups-groupId-patients-patientId-exams-deleted___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _2dc1146e,
    name: "keto-groups-groupId-patients-patientId-exams-deleted___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _fbd765a0,
    name: "groups-groupId-patients-patientId-bodyscan-grid___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3dc3b638,
    name: "groups-groupId-patients-patientId-exams-deleted___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _fbd765a0,
    name: "groups-groupId-patients-patientId-bodyscan-grid___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3dc3b638,
    name: "groups-groupId-patients-patientId-exams-deleted___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _fbd765a0,
    name: "groups-groupId-patients-patientId-bodyscan-grid___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3dc3b638,
    name: "groups-groupId-patients-patientId-exams-deleted___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _4c80d490,
    name: "regional-groups-groupId-patients-patientId-exams-deleted___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _fbd765a0,
    name: "groups-groupId-patients-patientId-bodyscan-grid___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3dc3b638,
    name: "groups-groupId-patients-patientId-exams-deleted___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _2d4208c1,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _9b6c254a,
    name: "groups-groupId-patients-patientId-exams-examId___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _02124516,
    name: "groups-groupId-patients-patientId-overview-formulaId___de"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _2d4208c1,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _9b6c254a,
    name: "groups-groupId-patients-patientId-exams-examId___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _02124516,
    name: "groups-groupId-patients-patientId-overview-formulaId___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _2d4208c1,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _9b6c254a,
    name: "groups-groupId-patients-patientId-exams-examId___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _02124516,
    name: "groups-groupId-patients-patientId-overview-formulaId___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _234d372a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _cf9c3a12,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _0904d06a,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _b1b0d498,
    name: "fitness-groups-groupId-patients-patientId-exams-examId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _98ab1faa,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _63f87158,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _15fff458,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _2d4208c1,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _9b6c254a,
    name: "groups-groupId-patients-patientId-exams-examId___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _02124516,
    name: "groups-groupId-patients-patientId-overview-formulaId___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _2d4208c1,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _9b6c254a,
    name: "groups-groupId-patients-patientId-exams-examId___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _02124516,
    name: "groups-groupId-patients-patientId-overview-formulaId___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?",
    component: _1301f42e,
    name: "keto-groups-groupId-patients-patientId-bia-biaId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?",
    component: _f55b570c,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?",
    component: _45cba80e,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _ba4d69de,
    name: "keto-groups-groupId-patients-patientId-exams-examId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?",
    component: _4b16e624,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?",
    component: _6fe6609e,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?",
    component: _21ede39e,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _2d4208c1,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _9b6c254a,
    name: "groups-groupId-patients-patientId-exams-examId___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _02124516,
    name: "groups-groupId-patients-patientId-overview-formulaId___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _2d4208c1,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _9b6c254a,
    name: "groups-groupId-patients-patientId-exams-examId___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _02124516,
    name: "groups-groupId-patients-patientId-overview-formulaId___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _2d4208c1,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _9b6c254a,
    name: "groups-groupId-patients-patientId-exams-examId___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _02124516,
    name: "groups-groupId-patients-patientId-overview-formulaId___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _26c02cdb,
    name: "regional-groups-groupId-patients-patientId-exams-examId___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _2d4208c1,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _9b6c254a,
    name: "groups-groupId-patients-patientId-exams-examId___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _02124516,
    name: "groups-groupId-patients-patientId-overview-formulaId___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _1eb0151e,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _6121f5c3,
    name: "groups-groupId-patients-patientId-exams-examId-bia___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _5dd00058,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _acc5612a,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _120a41b2,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _fa2e6b48,
    name: "groups-groupId-patients-patientId-exams-examId-measures___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _a4dafec2,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _ba92bfea,
    name: "groups-groupId-patients-patientId-exams-examId-reports___de"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _1eb0151e,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _6121f5c3,
    name: "groups-groupId-patients-patientId-exams-examId-bia___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _5dd00058,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _acc5612a,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _120a41b2,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _fa2e6b48,
    name: "groups-groupId-patients-patientId-exams-examId-measures___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _a4dafec2,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _ba92bfea,
    name: "groups-groupId-patients-patientId-exams-examId-reports___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _1eb0151e,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _6121f5c3,
    name: "groups-groupId-patients-patientId-exams-examId-bia___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _5dd00058,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _acc5612a,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _120a41b2,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _fa2e6b48,
    name: "groups-groupId-patients-patientId-exams-examId-measures___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _a4dafec2,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _ba92bfea,
    name: "groups-groupId-patients-patientId-exams-examId-reports___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _3dc3f0d3,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-bia___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _0535f629,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fitnessBia___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _6e92f02b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-fluidRetention___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _c5fb48b8,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-qualitative___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _533d3e64,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-quantitative___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _73a6071b,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _22ac0596,
    name: "fitness-groups-groupId-patients-patientId-cardio-cardioId-hr___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _37637002,
    name: "fitness-groups-groupId-patients-patientId-circumference-circumferenceId-radar___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _6c603f48,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-bia___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _4a1bb776,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _919fab00,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fitnessBia___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _751d2102,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-fluidRetention___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _41f9ee11,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-functional___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _29ee8b98,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-hr___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _8194e018,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-load___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _2a4eb22d,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-qualitative___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _1ac04936,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-quantitative___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _41924138,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/skinfoldRadar",
    component: _a1efe2ec,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-skinfoldRadar___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _5c69e79a,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-somatotype___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _046f9234,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-zscore___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _fb7ff40c,
    name: "fitness-groups-groupId-patients-patientId-fitness-fitnessId-functional___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _925710f0,
    name: "fitness-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _4b02c858,
    name: "fitness-groups-groupId-patients-patientId-trainings-trainingId-load___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _1eb0151e,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _6121f5c3,
    name: "groups-groupId-patients-patientId-exams-examId-bia___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _5dd00058,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _acc5612a,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _120a41b2,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _fa2e6b48,
    name: "groups-groupId-patients-patientId-exams-examId-measures___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _a4dafec2,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _ba92bfea,
    name: "groups-groupId-patients-patientId-exams-examId-reports___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _1eb0151e,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _6121f5c3,
    name: "groups-groupId-patients-patientId-exams-examId-bia___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _5dd00058,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _acc5612a,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _120a41b2,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _fa2e6b48,
    name: "groups-groupId-patients-patientId-exams-examId-measures___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _a4dafec2,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _ba92bfea,
    name: "groups-groupId-patients-patientId-exams-examId-reports___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/bia",
    component: _3878d554,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-bia___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fitnessBia",
    component: _49606f74,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fitnessBia___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/fluidRetention",
    component: _210c6bc8,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-fluidRetention___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/qualitative",
    component: _ebba65b2,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-qualitative___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/quantitative",
    component: _e561c0aa,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-quantitative___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports",
    component: _cb1fb844,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/cardio/:cardioId?/hr",
    component: _2b489adc,
    name: "keto-groups-groupId-patients-patientId-cardio-cardioId-hr___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/circumference/:circumferenceId?/radar",
    component: _2e9c5842,
    name: "keto-groups-groupId-patients-patientId-circumference-circumferenceId-radar___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _3a3948f9,
    name: "keto-groups-groupId-patients-patientId-exams-examId-bia___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/circumferenceRadar",
    component: _0400ce08,
    name: "keto-groups-groupId-patients-patientId-exams-examId-circumferenceRadar___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _0ab5928a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-estimates___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fitnessBia",
    component: _440b717a,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fitnessBia___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/fluidRetention",
    component: _8795b776,
    name: "keto-groups-groupId-patients-patientId-exams-examId-fluidRetention___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/functional",
    component: _68c40ad4,
    name: "keto-groups-groupId-patients-patientId-exams-examId-functional___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/hr",
    component: _4e971b5b,
    name: "keto-groups-groupId-patients-patientId-exams-examId-hr___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/ketone",
    component: _293bdac6,
    name: "keto-groups-groupId-patients-patientId-exams-examId-ketone___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/load",
    component: _5bf93af7,
    name: "keto-groups-groupId-patients-patientId-exams-examId-load___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/qualitative",
    component: _466fa46c,
    name: "keto-groups-groupId-patients-patientId-exams-examId-qualitative___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/quantitative",
    component: _e1545930,
    name: "keto-groups-groupId-patients-patientId-exams-examId-quantitative___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _4d80307e,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/somatotype",
    component: _0ed5ae14,
    name: "keto-groups-groupId-patients-patientId-exams-examId-somatotype___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/weightLoss",
    component: _8dc1f2e8,
    name: "keto-groups-groupId-patients-patientId-exams-examId-weightLoss___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/zscore",
    component: _784d2d29,
    name: "keto-groups-groupId-patients-patientId-exams-examId-zscore___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/fitness/:fitnessId?/functional",
    component: _1e286b17,
    name: "keto-groups-groupId-patients-patientId-fitness-fitnessId-functional___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/skinfolds/:skinfoldId?/radar",
    component: _9e450036,
    name: "keto-groups-groupId-patients-patientId-skinfolds-skinfoldId-radar___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/trainings/:trainingId?/load",
    component: _773493d7,
    name: "keto-groups-groupId-patients-patientId-trainings-trainingId-load___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _1eb0151e,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _6121f5c3,
    name: "groups-groupId-patients-patientId-exams-examId-bia___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _5dd00058,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _acc5612a,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _120a41b2,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _fa2e6b48,
    name: "groups-groupId-patients-patientId-exams-examId-measures___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _a4dafec2,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _ba92bfea,
    name: "groups-groupId-patients-patientId-exams-examId-reports___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _1eb0151e,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _6121f5c3,
    name: "groups-groupId-patients-patientId-exams-examId-bia___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _5dd00058,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _acc5612a,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _120a41b2,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _fa2e6b48,
    name: "groups-groupId-patients-patientId-exams-examId-measures___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _a4dafec2,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _ba92bfea,
    name: "groups-groupId-patients-patientId-exams-examId-reports___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _1eb0151e,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _6121f5c3,
    name: "groups-groupId-patients-patientId-exams-examId-bia___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _5dd00058,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _acc5612a,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _120a41b2,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _fa2e6b48,
    name: "groups-groupId-patients-patientId-exams-examId-measures___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _a4dafec2,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _ba92bfea,
    name: "groups-groupId-patients-patientId-exams-examId-reports___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _55bbd57a,
    name: "regional-groups-groupId-patients-patientId-exams-examId-bia___en"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _3ab55f8b,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports___en"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?/vector",
    component: _ef1303ec,
    name: "regional-groups-groupId-patients-patientId-exams-examId-vector___en"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/histories/:historyId?/attachments",
    component: _52938e32,
    name: "regional-groups-groupId-patients-patientId-histories-historyId-attachments___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _1eb0151e,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _6121f5c3,
    name: "groups-groupId-patients-patientId-exams-examId-bia___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _5dd00058,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _acc5612a,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _120a41b2,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _fa2e6b48,
    name: "groups-groupId-patients-patientId-exams-examId-measures___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _a4dafec2,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _ba92bfea,
    name: "groups-groupId-patients-patientId-exams-examId-reports___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _6e7bd5e1,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___de"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _6e7bd5e1,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _6e7bd5e1,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___es"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _6e7bd5e1,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _6e7bd5e1,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___it"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _6e7bd5e1,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _6e7bd5e1,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _6e7bd5e1,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___pl"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _6e7bd5e1,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _b3b6a1e8,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___de"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _b3b6a1e8,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _b3b6a1e8,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___es"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _b3b6a1e8,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _b3b6a1e8,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___it"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _b3b6a1e8,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _b3b6a1e8,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _b3b6a1e8,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___pl"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _b3b6a1e8,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _47264ada,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _14d11746,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___de"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _47264ada,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _14d11746,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _47264ada,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _14d11746,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___es"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _47264ada,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _14d11746,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _47264ada,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _14d11746,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___it"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _47264ada,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _14d11746,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _47264ada,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _14d11746,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _47264ada,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _14d11746,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___pl"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _47264ada,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _14d11746,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _386049c0,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _5358f8ca,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___de"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _386049c0,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _5358f8ca,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _386049c0,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _5358f8ca,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _44cf2c6a,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _6938df33,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _386049c0,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _5358f8ca,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _386049c0,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _5358f8ca,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug",
    component: _e1c586a6,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _5b8f4410,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _386049c0,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _5358f8ca,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _386049c0,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _5358f8ca,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _386049c0,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _5358f8ca,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug",
    component: _50ed5cda,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _386049c0,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _5358f8ca,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___zh"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _0743f2b4,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___de"
  }, {
    path: "/de/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _407b38dc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___de"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _0743f2b4,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___en-gb"
  }, {
    path: "/en-gb/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _407b38dc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___en-gb"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _0743f2b4,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___es"
  }, {
    path: "/es/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _407b38dc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___es"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _7dcb793c,
    name: "fitness-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___en"
  }, {
    path: "/fitness/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _32cfc0da,
    name: "fitness-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _0743f2b4,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___fr"
  }, {
    path: "/fr/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _407b38dc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___fr"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _0743f2b4,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___it"
  }, {
    path: "/it/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _407b38dc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___it"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/bia/:biaId?/reports/:reportSlug/iframe",
    component: _7d3f7159,
    name: "keto-groups-groupId-patients-patientId-bia-biaId-reports-reportSlug-iframe___en"
  }, {
    path: "/keto/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _3f0e5656,
    name: "keto-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _0743f2b4,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___nl-be"
  }, {
    path: "/nl-be/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _407b38dc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___nl-be"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _0743f2b4,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___nl"
  }, {
    path: "/nl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _407b38dc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___nl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _0743f2b4,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___pl"
  }, {
    path: "/pl/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _407b38dc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___pl"
  }, {
    path: "/regional/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportSlug/iframe",
    component: _15195268,
    name: "regional-groups-groupId-patients-patientId-exams-examId-reports-reportSlug-iframe___en"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _0743f2b4,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___zh"
  }, {
    path: "/zh/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _407b38dc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___zh"
  }, {
    path: "/groups/:groupId",
    component: _0178b60e,
    name: "groups-groupId___en"
  }, {
    path: "/groups/:groupId/bia",
    component: _59c0fce1,
    name: "groups-groupId-bia___en"
  }, {
    path: "/groups/:groupId/deleted",
    component: _2dad6e00,
    name: "groups-groupId-deleted___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?",
    component: _7d2431cc,
    name: "groups-groupId-patients-patientId___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/activities",
    component: _68ea83b4,
    name: "groups-groupId-patients-patientId-activities___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan",
    component: _46a14a58,
    name: "groups-groupId-patients-patientId-bodyscan___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/grid",
    component: _c6c6b188,
    name: "groups-groupId-patients-patientId-grid___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/schedule",
    component: _0b7b3f0d,
    name: "groups-groupId-patients-patientId-schedule___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan/grid",
    component: _fbd765a0,
    name: "groups-groupId-patients-patientId-bodyscan-grid___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/deleted",
    component: _3dc3b638,
    name: "groups-groupId-patients-patientId-exams-deleted___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId",
    component: _2d4208c1,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?",
    component: _9b6c254a,
    name: "groups-groupId-patients-patientId-exams-examId___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/overview/:formulaId?",
    component: _02124516,
    name: "groups-groupId-patients-patientId-overview-formulaId___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports",
    component: _1eb0151e,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/bia",
    component: _6121f5c3,
    name: "groups-groupId-patients-patientId-exams-examId-bia___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/estimates",
    component: _5dd00058,
    name: "groups-groupId-patients-patientId-exams-examId-estimates___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/hydra",
    component: _acc5612a,
    name: "groups-groupId-patients-patientId-exams-examId-hydra___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/interpretive",
    component: _120a41b2,
    name: "groups-groupId-patients-patientId-exams-examId-interpretive___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/measures",
    component: _fa2e6b48,
    name: "groups-groupId-patients-patientId-exams-examId-measures___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/percentiles",
    component: _a4dafec2,
    name: "groups-groupId-patients-patientId-exams-examId-percentiles___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports",
    component: _ba92bfea,
    name: "groups-groupId-patients-patientId-exams-examId-reports___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/edit",
    component: _6e7bd5e1,
    name: "groups-groupId-patients-patientId-exams-examId-reports-edit___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?",
    component: _b3b6a1e8,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/iframe",
    component: _47264ada,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-iframe___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/default/:reportSlug?/new",
    component: _14d11746,
    name: "groups-groupId-patients-patientId-exams-examId-reports-default-reportSlug-new___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug",
    component: _386049c0,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId",
    component: _5358f8ca,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/bodyscan/:bodyscanExamId/reports/:reportSlug/iframe",
    component: _0743f2b4,
    name: "groups-groupId-patients-patientId-bodyscan-bodyscanExamId-reports-reportSlug-iframe___en"
  }, {
    path: "/groups/:groupId/patients/:patientId?/exams/:examId?/reports/:reportId/iframe",
    component: _407b38dc,
    name: "groups-groupId-patients-patientId-exams-examId-reports-reportId-iframe___en"
  }, {
    path: "/",
    component: _129a5b32,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
