const state = () => ({
  list: null,
  exam: null
})

const getters = {
  list: state => state.list,
  exam: state => state.exam
}

const mutations = {
  receiveList(state, list) {
    state.list = list
  },
  receiveExam(state, exam) {
    state.exam = exam
  }
}

const actions = {
  getList({ commit, rootState }, patientId) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/fitness/list', {
        accessToken: rootState.user.token,
        patientId: patientId,
        filters: {
          singleOnly: true
        }
      })
      .then(response => {
        commit('receiveList', response.data.fitness)
      })
  },
  getExam({ commit, rootState }, examId) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/fitness/get', {
        accessToken: rootState.user.token,
        id: examId
      })
      .then(response => {
        commit('receiveExam', response.data.fitness)
      })
  },
  createExam({ dispatch, rootState, rootGetters }, exam) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/fitness/create', {
        accessToken: rootState.user.token,
        fitness: exam
      })
      .then(response => {
        if (!rootGetters['profile/wizard']) {
          dispatch('getList', exam.patientId)
        }
        if (rootState.profile.isBaseline) {
          dispatch('profile/getProfile', exam.patientId, { root: true })
        }
        return response
      })
  },
  updateExam({ dispatch, rootState }, exam) {
    return this.$axios
      .post('/fitness-app-backend/v1/app/fitness/update', {
        accessToken: rootState.user.token,
        fitness: exam
      })
      .then(() => {
        dispatch('getExam', exam.id)
        if (rootState.profile.isBaseline) {
          dispatch('profile/getProfile', exam.patientId, { root: true })
        }
      })
  },
  deleteExam({ rootState }, examId) {
    return this.$axios.post('/fitness-app-backend/v1/app/fitness/delete', {
      accessToken: rootState.user.token,
      id: examId
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
