<template>
  <BModal
    ref="license-expired-modal"
    :title="$t('app.common.licenseExpiredTitle')"
    centered
    no-close-on-backdrop
    no-close-on-esc
  >
    <div>
      {{ $t('app.common.licenseExpiredDescription') }}
    </div>

    <template #modal-footer="{ cancel }">
      <BButton variant="secondary" @click="cancel">
        {{ $t('app.forms.close') }}
      </BButton>
    </template>
  </BModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      applications: 'applications/list'
    })
  },
  methods: {
    ...mapActions({
      getApplications: 'applications/getApplications'
    }),
    async showModal() {
      await this.getApplications()
      if (this.applications && this.applications.length === 0) {
        this.$refs['license-expired-modal'].show()
      }
    }
  }
}
</script>
