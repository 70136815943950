export const vueI18n = {"fallbackLocale":"en"}
export const vueI18nLoader = false
export const locales = [{"code":"en","iso":"en-US","file":"en-US.json","name":"English"},{"code":"de","iso":"de-DE","file":"de-DE.json","name":"Deutsch"},{"code":"it","iso":"it-IT","file":"it-IT.json","name":"Italiano"},{"code":"nl","iso":"nl-NL","file":"nl-NL.json","name":"Dutch"},{"code":"nl-be","iso":"nl-BE","file":"nl-BE.json","name":"Flemish"},{"code":"es","iso":"es-ES","file":"es-ES.json","name":"Spanish"},{"code":"en-gb","iso":"en-GB","file":"en-GB.json","name":"United Kingdom"},{"code":"fr","iso":"fr-FR","file":"fr-FR.json","name":"French"},{"code":"pl","iso":"pl-PL","file":"pl-PL.json","name":"Polish"},{"code":"zh","iso":"zh-CN","file":"zh-CN.json","name":"Chinese"}]
export const defaultLocale = 'en'
export const defaultDirection = 'ltr'
export const routesNameSeparator = '___'
export const defaultLocaleRouteNameSuffix = 'default'
export const strategy = 'prefix_except_default'
export const lazy = true
export const langDir = 'lang/'
export const rootRedirect = null
export const detectBrowserLanguage = {"useCookie":true,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"alwaysRedirect":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false}
export const differentDomains = false
export const seo = false
export const baseUrl = ''
export const vuex = {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true}
export const parsePages = true
export const pages = {}
export const skipSettingLocaleOnNavigate = false
export const beforeLanguageSwitch = () => null
export const onLanguageSwitched = () => null
export const IS_UNIVERSAL_MODE = false
export const MODULE_NAME = 'nuxt-i18n'
export const LOCALE_CODE_KEY = 'code'
export const LOCALE_ISO_KEY = 'iso'
export const LOCALE_DIR_KEY = 'dir'
export const LOCALE_DOMAIN_KEY = 'domain'
export const LOCALE_FILE_KEY = 'file'
export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}
export const COMPONENT_OPTIONS_KEY = 'nuxtI18n'
export const localeCodes = ["en","de","it","nl","nl-be","es","en-gb","fr","pl","zh"]
export const trailingSlash = undefined
