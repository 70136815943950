const state = () => ({
  list: null,
  deleted: null,
  history: null
})

const getters = {
  list: state => state.list,
  deleted: state => state.deleted,
  history: state => state.history
}

const mutations = {
  receiveList(state, list) {
    state.list = list
  },
  receiveDeleted(state, list) {
    state.deleted = list
  },
  receiveHistory(state, history) {
    state.history = history
  }
}

const actions = {
  getList({ commit, rootState }, patientId) {
    return this.$axios
      .post('/regional-app-backend/v1/app/histories/list', {
        accessToken: rootState.user.token,
        patientId: patientId,
        filters: {
          deleted: false
        }
      })
      .then(response => {
        commit('receiveList', response.data.histories)
      })
  },
  getDeleted({ commit, rootState }, patientId) {
    return this.$axios
      .post('/regional-app-backend/v1/app/histories/list', {
        accessToken: rootState.user.token,
        patientId: patientId,
        filters: {
          deleted: true
        }
      })
      .then(response => {
        commit('receiveDeleted', response.data.histories)
      })
  },
  restoreHistory({ rootState }, historyId) {
    return this.$axios.post('/regional-app-backend/v1/app/histories/restore', {
      accessToken: rootState.user.token,
      id: historyId
    })
  },
  getHistory({ commit, rootState }, historyId) {
    return this.$axios
      .post('/regional-app-backend/v1/app/histories/get', {
        accessToken: rootState.user.token,
        id: historyId
      })
      .then(response => {
        commit('receiveHistory', response.data.history)
        return response
      })
  },
  createHistory({ dispatch, rootState }, history) {
    return this.$axios
      .post('/regional-app-backend/v1/app/histories/create', {
        accessToken: rootState.user.token,
        history: history
      })
      .then(() => {
        dispatch('getList', history.patientId)
      })
  },
  updateHistory({ dispatch, rootState }, history) {
    return this.$axios
      .post('/regional-app-backend/v1/app/histories/update', {
        accessToken: rootState.user.token,
        id: history.id,
        history: history
      })
      .then(() => {
        dispatch('getHistory', history.id)
      })
  },
  deleteHistory({ rootState }, historyId) {
    return this.$axios.post('/regional-app-backend/v1/app/histories/delete', {
      accessToken: rootState.user.token,
      id: historyId
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
