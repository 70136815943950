const state = () => ({
  report: null,
  list: [
    {
      slug: 'ketoBaseline',
      name: 'ketoBaseline',
      json: [
        {
          items: [
            {
              component: 'ReportHeader'
            }
          ]
        },
        {
          items: [
            {
              component: 'Profile'
            },
            {
              component: 'Somatotype'
            }
          ]
        },
        {
          items: [
            {
              component: 'Estimates'
            }
          ]
        },
        {
          items: [
            {
              title: 'Hydragram®',
              component: 'Hydragram',
              bg: 'warning',
              image: 'estimates-vertical'
            }
          ]
        },
        {
          items: [
            {
              title: 'Nutrigram®',
              component: 'Nutrigram',
              bg: 'warning',
              image: 'estimates-vertical'
            }
          ]
        },
        {
          items: [
            {
              component: 'Anthropometric'
            }
          ]
        },
        {
          items: [
            {
              component: 'Bia'
            }
          ]
        },
        {
          items: [
            {
              component: 'Questionnaire'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportFooter'
            }
          ]
        }
      ]
    },
    {
      slug: 'ketoStandard',
      name: 'ketoStandard',
      offline: true,
      json: [
        {
          items: [
            {
              component: 'ReportStandardHeader'
            }
          ]
        },
        {
          items: [
            {
              title: 'Hydragram®',
              component: 'Hydragram',
              bg: 'warning',
              image: 'estimates-vertical'
            }
          ]
        },
        {
          items: [
            {
              component: 'StandardBia'
            }
          ]
        },
        {
          items: [
            {
              component: 'CompareTable'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportFooter'
            }
          ]
        }
      ]
    },
    {
      slug: 'ketoFollowUp',
      name: 'ketoFollowUp',
      json: [
        {
          items: [
            {
              component: 'ReportHeader'
            }
          ]
        },
        {
          items: [
            {
              component: 'FollowUpBia'
            }
          ]
        },
        {
          items: [
            {
              component: 'FollowUpWeightLoss'
            }
          ]
        },
        {
          items: [
            {
              component: 'FollowUpAnthropometric'
            }
          ]
        },
        {
          items: [
            {
              component: 'ReportFooter'
            }
          ]
        }
      ]
    },
    {
      slug: 'penta',
      name: 'penta',
      json: [
        {
          items: [
            {
              component: 'Penta'
            }
          ]
        },
        {
          items: [
            {
              component: 'PentaFinal'
            }
          ]
        }
      ]
    }
  ]
})

const getters = {
  report: state => state.report,
  list: state => state.list
}

const mutations = {
  receiveReport(state, report) {
    state.report = report
  }
}

const actions = {
  getReport({ state, commit }, reportSlug) {
    const report = state.list.find(report => report.slug === reportSlug)
    commit('receiveReport', report)
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
