export default (context, inject) => {
  function examNewFormula(oldFormula) {
    switch (oldFormula) {
      case '1.3 (Proprietary)':
        return 'AKERN 2023'
      case 'AKERN 2023':
        return '1.3 (Proprietary)'
      case 'Athlete':
        return '1.3 (Proprietary)'
    }
  }
  inject('examNewFormula', examNewFormula)

  context.$examNewFormula = examNewFormula
}
