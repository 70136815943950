export default (context, inject) => {
  function isHavePermissions(path) {
    const permissions = context.store.getters['user/permissions']

    if (permissions) {
      let result = false

      outer: for (let i = 0; i < permissions.length; i++) {
        const statePermission = permissions[i].split('/')
        const currentPermission = path.split('/')

        for (let j = 0; j < currentPermission.length; j++) {
          if (
            currentPermission[j] === statePermission[j] ||
            statePermission[j] === '*'
          ) {
            if (
              j === currentPermission.length - 1 ||
              j === statePermission.length - 1
            ) {
              result = true
              break outer
            }
          } else {
            break
          }
        }
      }

      return result
    }

    return false
  }

  inject('isHavePermissions', isHavePermissions)

  context.$isHavePermissions = isHavePermissions
}
