<template>
  <div class="login default-layout">
    <network-status v-if="$platform === 'electron'" />
    <navbar-login />
    <nuxt />
  </div>
</template>

<script>
import NavbarLogin from '~/components/navbar-login.vue'
import NetworkStatus from '~/components/networkStatus'

export default {
  components: {
    NavbarLogin,
    NetworkStatus
  }
}
</script>

<style lang="scss">
.login {
  min-height: 100vh;
}
</style>
