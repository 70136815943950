export default function({ app, store, redirect }) {
  const fitnessApplication = store.getters['applications/fitness']

  if (
    fitnessApplication &&
    fitnessApplication.signature &&
    ((typeof fitnessApplication.signature.daysToLock === 'number' &&
      fitnessApplication.signature.daysToLock <= 0) ||
      fitnessApplication.signature.forceSigningContract)
  ) {
    redirect(app.localePath('/accept?app=FIT'))
  }
}
