<template>
  <div class="d-flex align-items-center">
    <img
      style="height: 60px"
      :src="`${$assetsBase}images/icons/fapps/penta-full.svg`"
      alt="Penta App"
    />
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    }
  }
}
</script>
