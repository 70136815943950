const state = () => ({
  crumb: null
})
const getters = {
  crumb: state => state.crumb
}
const mutations = {
  setCrumb(state, crumb) {
    state.crumb = crumb
  }
}
export default {
  state,
  getters,
  mutations,
  namespaced: true
}
