const state = () => ({
  histories: null,
  history: null,
  exams: null,
  exam: null,
  prefillExam: null,
  baseline: null,
  historiesDeleted: null,
  bia: null,
  circumference: null,
  somatotype: null,
  references: null,
  referencesList: null,
  bloodTest: null,
  questionnaireAnswers: null,
  estimates: null,
  nutrigram: null,
  hydragram: null,
  weightLoss: null,
  ketoneIndexes: null,
  pentaData: null
})

const getters = {
  historiesList: state => state.histories,
  historiesDeleted: state => state.historiesDeleted,
  history: state => state.history,
  exams: state => state.exams,
  exam: state => state.exam,
  prefillExam: state => state.prefillExam,
  baseline: state => state.baseline,
  bia: state => state.bia,
  circumference: state => state.circumference,
  somatotype: state => state.somatotype,
  references: state => state.references,
  referencesList: state => state.referencesList,
  bloodTest: state => state.bloodTest,
  questionnaire: state => state.questionnaire,
  questionnaireAnswers: state => state.questionnaireAnswers,
  wizard: _ => [
    'questionnaire',
    'bia',
    'circumference',
    'somatotype',
    'bloodTest'
  ],
  modalIds: state => {
    // bloodTest and questionnarie needs exam id as they are complex object
    return {
      questionnaire:
        state.exam && state.exam.questionnaire
          ? 'questionnaire-' + state.exam.id
          : 'questionnaire-add',
      bia:
        state.exam && state.exam.biaId ? 'bia-' + state.exam.biaId : 'bia-add',
      circumference:
        state.exam && state.exam.circumference
          ? 'circumference-' + state.exam.circumference.id
          : 'circumference-add',
      somatotype:
        state.exam && state.exam.somatotype
          ? 'somatotype-' + state.exam.somatotype
          : 'somatotype-add',
      bloodTest:
        state.exam && state.exam.bloodTest
          ? 'bloodTest-' + state.exam.id
          : 'bloodTest-add'
    }
  },
  estimates: (state, getters, rootState) => {
    if (state.exam && state.estimates) {
      const newEstimates = state.estimates.map(estimate => {
        if (
          [
            'weight',
            'idealWeight',
            'pha',
            'fm',
            'ffm',
            'ecw',
            'bmr',
            'tdee',
            'bmi',
            'fmi',
            'smi',
            'ecwi',
            'ketone'
          ].includes(estimate.name)
        ) {
          return estimate
        } else {
          return {
            printable: true,
            name: estimate.name,
            title: rootState.dictionary[estimate.name] || estimate.name,
            value: 0,
            rawValue: 0,
            referenceValue: null,
            rawReferenceValue: null,
            segments: null
          }
        }
      })

      return rootState.user.settings.showIdealWeight
        ? newEstimates
        : Array.isArray(state.estimates) && state.estimates.length
        ? newEstimates.filter(item => item.name !== 'idealWeight')
        : newEstimates
    }
  },
  nutrigram: state => state.nutrigram,
  hydragram: state => state.hydragram,
  weightLoss: state => state.weightLoss,
  ketoneIndexes: state => state.ketoneIndexes,
  pentaData: (state, getters) => {
    if (state.pentaData) {
      let mealsGroups = null
      if (state.pentaData.meals.length > 0) {
        mealsGroups = state.pentaData.meals.reduce((acc, ref) => {
          if (acc.length === 0 || acc[acc.length - 1].length === 2) {
            acc.push([ref])
          } else {
            acc[acc.length - 1].push(ref)
          }
          return acc
        }, [])
      }
      return {
        ...state.pentaData,
        mealsGroups
      }
    }
  }
}

const mutations = {
  receiveHistoriesList(state, list) {
    state.histories = list
  },
  receiveHistoriesDeleted(state, list) {
    state.historiesDeleted = list
  },
  receiveHistory(state, history) {
    state.history = history
  },
  receiveExams(state, exams) {
    state.exams = exams
  },
  receiveCircumference(state, circumference) {
    state.circumference = circumference
  },
  receiveBaseline(state, exam) {
    state.baseline = exam
  },
  receiveReferences(state, references) {
    state.references = references
  },
  receiveReferencesList(state, references) {
    state.referencesList = references
  },
  receiveQuestionnaire(state, questionnaire) {
    state.questionnaire = questionnaire
  },
  receiveNutrigram(state, nutrigram) {
    state.nutrigram = nutrigram
  },
  receiveHydragram(state, hydragram) {
    state.hydragram = hydragram
  },
  receiveWeightLoss(state, weightLoss) {
    state.weightLoss = weightLoss
  },
  receiveKetoneIndexes(state, ketoneIndexes) {
    state.ketoneIndexes = ketoneIndexes
  },
  setHistory(state, history) {
    state.history = history
  },
  setBia(state, bia) {
    state.bia = bia
  },
  setCircumference(state, circumference) {
    state.circumference = circumference
  },
  setSomatotype(state, somatotype) {
    state.somatotype = somatotype
  },
  setBloodTest(state, bloodTest) {
    state.bloodTest = bloodTest
  },
  setQuestionnaireAnswers(state, questionnaireAnswers) {
    state.questionnaireAnswers = questionnaireAnswers
  },
  setExam(state, exam) {
    state.exam = exam
  },
  setPrefillExam(state, exam) {
    state.prefillExam = exam
  },
  receiveEstimates(state, estimates) {
    state.estimates = estimates
  },
  addEstimates(state, estimatesArray) {
    estimatesArray.forEach(estimate => {
      state.estimates.push(estimate)
    })
  },
  addWeightToEstimates(state, weightEstimate) {
    state.estimates.unshift(weightEstimate)
  },
  togglePrintable(state, itemId) {
    state.estimates.find(
      item => item.id === itemId
    ).printable = !state.estimates.find(item => item.id === itemId).printable
  },
  receivePentaData(state, penta) {
    state.pentaData = penta
  }
}

const actions = {
  getHistoriesList({ commit, rootState }, patientId) {
    return this.$axios
      .post('/keto-app-backend/v1/app/histories/list', {
        accessToken: rootState.user.token,
        patientId: patientId,
        filters: {
          deleted: false
        }
      })
      .then(response => {
        commit('receiveHistoriesList', response.data.histories)
      })
  },
  getExams({ commit, rootState }, patientId) {
    return this.$axios
      .post('/keto-app-backend/v1/app/exams/list', {
        accessToken: rootState.user.token,
        patientId: patientId
      })
      .then(response => {
        commit('receiveExams', response.data.exams)
        const baselineExam = response.data.exams.find(e => e.isBaseline === 1)
        if (baselineExam) {
          commit('receiveBaseline', { ...baselineExam })
        }
      })
  },
  getBaseline({ commit, rootState }, patientId) {
    return this.$axios
      .post('/keto-app-backend/v1/app/exams/list', {
        accessToken: rootState.user.token,
        patientId: patientId
      })
      .then(response => {
        const baselineExam = response.data.exams.find(e => e.isBaseline === 1)
        if (baselineExam) {
          commit('receiveBaseline', { ...baselineExam })
        }
      })
  },
  getExam({ commit, rootState }, examId) {
    return this.$axios
      .post('/keto-app-backend/v1/app/exams/get', {
        accessToken: rootState.user.token,
        id: examId
      })
      .then(response => {
        return this.$axios
          .post('/keto-app-backend/v1/app/histories/get', {
            accessToken: rootState.user.token,
            id: response.data.exam.historyId
          })
          .then(hRes => {
            // console.log(hRes)
            response.data.exam.history = hRes.data.history
            commit('setExam', response.data.exam)
          })
      })
  },
  getHistoriesDeleted({ commit, rootState }, patientId) {
    return this.$axios
      .post('/keto-app-backend/v1/app/histories/list', {
        accessToken: rootState.user.token,
        patientId: patientId,
        filters: {
          deleted: true
        }
      })
      .then(response => {
        commit('receiveHistoriesDeleted', response.data.histories)
      })
  },
  restoreHistory({ rootState }, historyId) {
    return this.$axios.post('/keto-app-backend/v1/app/histories/restore', {
      accessToken: rootState.user.token,
      id: historyId
    })
  },
  getHistory({ commit, rootState }, historyId) {
    return this.$axios
      .post('/keto-app-backend/v1/app/histories/get', {
        accessToken: rootState.user.token,
        id: historyId
      })
      .then(response => {
        commit('receiveHistory', response.data.history)
        return response
      })
  },
  createHistory({ dispatch, rootState }, history) {
    return this.$axios
      .post('/keto-app-backend/v1/app/histories/create', {
        accessToken: rootState.user.token,
        history: history
      })
      .then(() => {
        dispatch('getHistoriesList', history.patientId)
      })
  },
  updateHistory({ dispatch, rootState }, history) {
    return this.$axios
      .post('/keto-app-backend/v1/app/histories/update', {
        accessToken: rootState.user.token,
        id: history.id,
        history: history
      })
      .then(() => {
        dispatch('getHistory', history.id)
      })
  },
  deleteHistory({ rootState }, historyId) {
    return this.$axios.post('/keto-app-backend/v1/app/histories/delete', {
      accessToken: rootState.user.token,
      id: historyId
    })
  },
  createBia({ commit, rootState }, bia) {
    return this.$axios
      .post('/keto-app-backend/v1/app/bia/create', {
        accessToken: rootState.user.token,
        bia
      })
      .then(response => {
        commit('setBia', response.data.bia)
      })
  },
  updateBia({ commit, rootState }, bia) {
    return this.$axios
      .post('/keto-app-backend/v1/app/bia/update', {
        accessToken: rootState.user.token,
        bia
      })
      .then(response => {
        commit('setBia', response.data.bia)
      })
  },
  createCircumference({ commit, rootState }, circumference) {
    return this.$axios
      .post('/keto-app-backend/v1/app/circumferences/create', {
        accessToken: rootState.user.token,
        circumference
      })
      .then(response => {
        commit('setCircumference', response.data.circumference)
      })
  },
  createExam({ dispatch, rootState }, exam) {
    return this.$axios
      .post('/keto-app-backend/v1/app/exams/create', {
        accessToken: rootState.user.token,
        exam
      })
      .then(response => {
        dispatch('getExams', response.data.exam.patientId)
      })
  },
  updateExam({ dispatch, rootState, rootGetters }, exam) {
    return this.$axios
      .post('/keto-app-backend/v1/app/exams/update', {
        accessToken: rootState.user.token,
        exam: exam
      })
      .then(() => {
        dispatch('getExam', exam.id)
      })
  },
  deleteExam({ rootState }, examId) {
    return this.$axios.post('/keto-app-backend/v1/app/exams/delete', {
      accessToken: rootState.user.token,
      id: examId
    })
  },
  getCircumference({ commit, rootState }, circumferenceId) {
    return this.$axios
      .post('/keto-app-backend/v1/app/circumferences/get', {
        accessToken: rootState.user.token,
        id: circumferenceId
      })
      .then(response => {
        commit('receiveCircumference', response.data.circumference)
      })
  },
  updateCircumference({ commit, rootState }, circumference) {
    const currentCircumference = Object.assign({}, circumference)
    return this.$axios
      .post('/keto-app-backend/v1/app/circumferences/update', {
        accessToken: rootState.user.token,
        circumference: currentCircumference
      })
      .then(response => {
        commit('receiveCircumference', response.data.circumference)
      })
  },
  getEstimates({ commit, rootState }, data) {
    return this.$axios
      .post('/keto-app-backend/v1/app/functions/estimates', {
        accessToken: rootState.user.token,
        biaId: data.biaId,
        type: data.type
      })
      .then(response => {
        const result = []

        let resultCount = 0

        for (const property in response.data.estimates) {
          if (response.data.estimates[property] !== null) {
            let itemValue

            if (
              response.data.estimates[property].ticks.find(
                tick => tick.key === 'current'
              )
            ) {
              itemValue = response.data.estimates[property].ticks.find(
                tick => tick.key === 'current'
              ).value
            } else {
              itemValue = null
            }

            let itemReferenceValue

            if (
              response.data.estimates[property].ticks.find(
                tick => tick.key === 'baseline'
              )
            ) {
              itemReferenceValue = response.data.estimates[property].ticks.find(
                tick => tick.key === 'baseline'
              ).value
            } else {
              itemReferenceValue = null
            }

            let itemMeanValue

            if (property === 'icw') {
              if (
                response.data.ecw &&
                response.data.ecw.ticks.find(tick => tick.key === 'mean')
              ) {
                itemMeanValue =
                  100 -
                  response.data.ecw.ticks.find(tick => tick.key === 'mean')
                    .value
              } else {
                itemMeanValue = null
              }
            } else if (
              response.data.estimates[property].ticks.find(
                tick => tick.key === 'mean'
              )
            ) {
              itemMeanValue = response.data.estimates[property].ticks.find(
                tick => tick.key === 'mean'
              ).value
            } else {
              itemMeanValue = null
            }

            const examItem = {
              name: property,
              title: rootState.dictionary[property] || property,
              value: itemValue,
              rawValue: response.data.estimates[property].ticks.find(
                tick => tick.key === 'absolute'
              )
                ? response.data.estimates[property].ticks.find(
                    tick => tick.key === 'absolute'
                  ).value
                : null,
              referenceValue: itemReferenceValue,
              rawReferenceValue: response.data.estimates[property].ticks.find(
                tick => tick.key === 'absolute-baseline'
              )
                ? response.data.estimates[property].ticks.find(
                    tick => tick.key === 'absolute-baseline'
                  ).value
                : null,
              meanValue: itemMeanValue,
              segments: null
            }

            const segments = []

            let item

            if (response.data.estimates[property].ranges) {
              item = response.data.estimates[property].ranges
            }

            if (item) {
              item
                .sort((a, b) => {
                  if (a.start > b.start) {
                    return 1
                  }
                  if (a.start < b.start) {
                    return -1
                  }
                  return 0
                })
                .forEach(segment => {
                  segments.push({
                    start: segment.start,
                    end: segment.end,
                    color: segment.color
                  })
                })
            }

            examItem.segments = segments
            examItem.printable = true
            examItem.id = resultCount

            result.push(examItem)

            resultCount++
          }
        }

        const resultSorted = []
        const sortOrder = [
          'idealWeight',
          'pha',
          'fm',
          'ffm',
          'ecw',
          'bmr',
          'tdee',
          'bmi',
          'fmi',
          'smi',
          'ecwi',
          'ketone'
        ]

        // if (response.data.exam.age < 16) {
        //   if (response.data.exam.age >= 5) {
        //     sortOrder.unshift('bmi')
        //   }
        //   sortOrder.unshift('height')
        // }

        sortOrder.forEach(key => {
          const foundedItem = result.find(item => item.name === key)
          if (foundedItem) {
            resultSorted.push(foundedItem)
          } else {
            // console.log('item ' + key + ' not found')
          }
        })

        // console.log(resultSorted)

        commit('receiveEstimates', resultSorted)
      })
  },
  getNutrigram({ commit, rootState }, biaId) {
    return this.$axios
      .post('/keto-app-backend/v1/app/functions/nutrigram', {
        accessToken: rootState.user.token,
        biaId: biaId
      })
      .then(response => {
        commit('receiveNutrigram', response.data.scale)
      })
  },
  getHydragram({ commit, rootState }, biaId) {
    return this.$axios
      .post('/keto-app-backend/v1/app/functions/hydragram', {
        accessToken: rootState.user.token,
        biaId: biaId
      })
      .then(response => {
        commit('receiveHydragram', response.data.scale)
      })
  },
  getWeightLoss({ commit, rootState }, historyId) {
    return this.$axios
      .post('/keto-app-backend/v1/app/charts/weight-loss', {
        accessToken: rootState.user.token,
        historyId: historyId
      })
      .then(response => {
        commit('receiveWeightLoss', response.data)
      })
  },
  getKetoneIndexes({ commit, rootState }, data) {
    // measuring possible values mmol/l and mg/dl
    return this.$axios
      .post('/keto-app-backend/v1/app/charts/ketone-indexes', {
        accessToken: rootState.user.token,
        historyId: data.historyId,
        measuring: data.measuring || 'mg/dl'
      })
      .then(response => {
        commit('receiveKetoneIndexes', response.data)
      })
  },
  getReferences({ commit, rootState }, patientId) {
    return this.$axios
      .post('/keto-app-backend/v1/app/references/list', {
        accessToken: rootState.user.token,
        filters: {
          category: null
        },
        patientId: patientId
      })
      .then(response => {
        commit(
          'receiveReferences',
          response.data.results.reduce((acc, r, i) => {
            acc[r.id] = { ...r }
            return acc
          }, {})
        )
        commit('receiveReferencesList', response.data.results)
      })
  },
  getQuestionnaire({ commit, rootState }, protocol) {
    return Promise.all(
      ['common', protocol].map(protocolName =>
        this.$axios.post('/keto-app-backend/v1/app/questionnaires/get', {
          accessToken: rootState.user.token,
          language: 'it',
          protocol: protocolName
        })
      )
    ).then(([commonRes, specificRes]) => {
      commit('receiveQuestionnaire', [
        ...(commonRes.data.questionnaire?.definition || []),
        ...(specificRes.data.questionnaire?.definition || [])
      ])
    })
  },
  getPentaData({ commit, rootState }, data) {
    const pentaUrl =
      process.env.releaseStage === 'production'
        ? 'https://shop.pentadiet.it/api/v1/akern'
        : 'https://penta2.conversa-dev.it/api/v1/akern'
    return this.$axios
      .get(pentaUrl, {
        params: {
          token: 'WqgUrP5mWu4hEVd6W7CCwhTdSg4mUf4knvAR',
          ...data
        }
      })
      .then(response => {
        // console.log(response.data)
        commit('receivePentaData', response.data)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
