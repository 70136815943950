<template>
  <div class="default-layout">
    <network-status v-if="$platform === 'electron'" />
    <sync v-if="$platform === 'electron'" />
    <navbar fapp="keto" />
    <div class="d-flex">
      <sidebar />
      <div
        class="content bg-keto"
        :style="
          'background-image: url(' +
            $assetsBase +
            'images/icons/fapps/keto.svg);'
        "
      >
        <breadcrumbs
          v-if="
            !$route.name.startsWith('index__') &&
              !$route.name.startsWith('keto__') &&
              !$route.name.startsWith('regional__')
          "
        />
        <nuxt />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Navbar from '~/components/navbar.vue'
import Breadcrumbs from '~/components/keto/breadcrumbs'
import Sidebar from '~/components/keto/sidebar'
import NetworkStatus from '~/components/networkStatus'
import Sync from '~/components/sync'

export default {
  components: {
    Breadcrumbs,
    Navbar,
    Sidebar,
    NetworkStatus,
    Sync
  },
  middleware: ['router', 'auth', 'keto'],
  computed: {
    ...mapGetters({
      user: 'user/user',
      settings: 'user/settings'
    })
  },
  created() {
    if (!this.settings) {
      this.getSettings()
    }
    const locale = this.user ? this.user.locale.slice(0, 2) : 'en'
    this.$dayjs.locale(locale)
  },
  updated() {
    this.$dayjs.locale(this.$i18n.locale)
  },
  methods: {
    ...mapActions({
      getSettings: 'user/getSettings'
    })
  }
}
</script>

<style lang="scss">
.bg-keto {
  background-repeat: no-repeat;
  background-size: 500px;
  background-position: right 32px;
}
</style>
